import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux"
import redux from "./reducers"
import { createStore, applyMiddleware, compose } from "redux"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import { loadState, saveState} from "./localstorage"
import thunk from "redux-thunk";

const logger = store => next => action => {
  console.group(action.type)
  console.info("dispatching", action)
  let result = next(action)
  console.log("next state", store.getState())
  console.groupEnd(action.type)
  return result
}


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const presist= loadState()
const store = createStore(
  redux,
  presist,
  composeEnhancers(
    applyMiddleware(logger, thunk)
  )
)


store.subscribe(()=>{
  saveState({
    user: store.getState().user.user
  });
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>,
  
);

// ReactDOM.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App/>
//     </BrowserRouter>
//   </Provider>,
//   document.getElementById("root")
// );

serviceWorker.register();
