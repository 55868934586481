import axios from "axios";

const api = process.env.REACT_APP_API_URL
// let token = localStorage.token

// if (!token)
//   token = localStorage.token = Math.random().toString(36).substr(-8)

const headers = {
  "accept": "application/json",
  "Acces-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true,
}



export const get = () =>
  fetch(`${api}/register`, { headers })
    .then(res => res.json())
    .then(data => data.contacts)

export const remove = (contact) =>
  fetch(`${api}/contacts/${contact.id}`, { method: "DELETE", headers })
    .then(res => res.json())
    .then(data => data.contact)


// let body={
//   "name": "string",
//   "email": "a@gmai.com",
//   "password": "string",
//   "org_email": "string",
//   "is_active": true
// }
export const register  = (body) => {
  try {
    return axios({
      method: "post",
      url: `${api}/register`,
      data: body,
      headers:{...headers,  "Content-Type": "application/json"}
    });
  } catch (error) {
    console.error(error)
  }
}
export const login  = (body) => {
  try {
    return axios({
      method: "post",
      url: `${api}/auth/token
      `,
      data: body,
      headers:{...headers,  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}
    });
  } catch (error) {
    console.error(error)
  }
}
export const UploadFilesService  = (path,data,threshold=0.65) => {
  try {
    return axios({
      method: "post",
      url: `${api}/${path}/`,
      params: {
        threshold
      },
      data,
      headers:{...headers,  "Content-Type": "multipart/form-data"}
    });
  } catch (error) {
    console.error(error)
  }
}

export const text  = (body) => {
  try {
    return axios({
      method: "post",
      url: `${api}/text`,
      data: body,
      headers:{...headers,  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}
    });
  } catch (error) {
    console.error(error)
  }
}