import React, { Component } from "react";
import "../../style/login.scss"
import log from "../../utils/assets/bg-log.svg";
import * as ContactsAPI from "../../Contact-api"
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux"
import { addUser } from "../../actions"
import PropTypes from "prop-types";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username:"",
      password:"",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  static get propTypes() { 
    return { 
      user: PropTypes.any,
      todoList:PropTypes.any
    }; 
  }
  componentDidMount(){
    console.log(this.props.todoList)
  }

  handleChange(event) {
    this.setState({
      [event.target.name] : event.target.value 
    });

  }

  onShowAlert = ()=>{
    this.setState({visible:true},()=>{
      window.setTimeout(()=>{
        this.setState({warning_msg:null})
      },12000)
    });
  }


  handleSubmit(event) {
    event.preventDefault();
    var formData = new FormData();
    formData.append("username",this.state.username);
    formData.append("password",this.state.password);
    console.log(formData)
    ContactsAPI.login((formData)).then((res) => {
      console.log(res)
      if (res.data.access_token){
        this.props.user(this.state.username,res.data.access_token)
        this.setState({login:true})

      }
    }).catch((error) => {
      if (error.response&& error.response.status ) {
        this.setState({warning_msg:error.response.data.detail})
      }
      else{
        console.log(error)
      }
    })
  
    
  }
  render() {
    let { warning_msg, login } = this.state
    if (login) {
      return <Redirect to='/' />
    }
    return (
      <body id="login">        
        <div className="h-60"></div>
        <div className="login">
          <div className="login-form">
            <div className="row no-gutters spacing">
              <div className="col-md-7 col-10">
                <div className="row no-gutters inner-log">	
                  <div className="col-md-7">
                    <img src={log} alt="Vision lang" className="img-fluid"></img>
                  </div>	
                  <div className="col-md-5">
                    <div className="right-section">
                      <h2>Login</h2>
                      <form onSubmit={this.handleSubmit}> 
                        <input type="username"     placeholder="Enter email"  name="username"  value={this.state.email}    onChange={this.handleChange} required/>
                        <input type="password"  placeholder="Password"  name="password" value={this.state.password}  onChange={this.handleChange} required/>
                        <div className="remember">
                          <label>
                            <input type="checkbox" checked="checked" name="remember" /> Remember me
                          </label>
                          <span className="psw">Forgot <a >password?</a></span>
                        </div>
                        <button type="submit">Login</button>
                      </form>
                      <h6>
                        <Link rel="preload"   to={"/sign-up"}>Create Account
                          <span className="sr-only">(current)</span></Link></h6>

                    </div>
                    {warning_msg &&
                      <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>{warning_msg}</strong>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    );
  }
  
}

function mapStateToProps(state) {
  const { user  } = state
  return { todoList: {user} }
}

// component will receive: props.a, props.todos, and props.filter
function mapDispatchToProps (dispatch) {
  return {
    user: (name,token) => dispatch(addUser(name,token)),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

