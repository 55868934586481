import React, { Component } from "react"
import { connect } from "react-redux"
import "../../style/product-detail.scss"
import "../../style/contact-us.scss"
// import log from "../../utils/assets/bg-log.svg";
import planetGreen from "../../utils/assets/planet-green.svg";
// import planetPink from "../../utils/assets/planet-pink.svg";
// import planetBlue from "../../utils/assets/planet-blue.svg";
// import cldSml from "../../utils/assets/cloud-small.svg";
import cldMed from "../../utils/assets/cloud-med.svg";
// import astr from "../../utils/assets/astr.svg";
// import asteroidRound from "../../utils/assets/asteroid-round.svg";
import cloudBtm from "../../utils/assets/cloud-btm.svg";
// import planetRing from "../../utils/assets/planet-ring.svg";
import planetGreen2 from "../../utils/assets/planet_green.svg";
// import MeteorShoot from "../../utils/assets/meteor-shoot.svg";
// import planetary from "../../utils/assets/planetary.svg";
import planetYellow2 from "../../utils/assets/planet-yellow.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import planetPnk from "../../utils/assets/planet-pnk.svg";
// import planetBlu from "../../utils/assets/planet_blue.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import ufoO from "../../utils/assets/ufo-o.svg";
// import shootingStar from "../../utils/assets/shooting.png";
// import computer from "../../utils/assets/computer-file-globe-Digital Marketing.svg";
// import PropTypes from "prop-types";
// import { Link } from "react-router-dom";

import { ADD_NAVBAR } from "../../actions"


class Contact extends Component {

  render() {
    // const { remove } = this.props
    return (
      <body id="product-detail" className="contact">
        <div className="h-90"></div>
        <div className="hero-section crowd">
          <div className="bg-sky">
            <img src={planetGreen} width="160" alt="Vision lang" className="planet-green img-fluid illustration-mob" />
            <div className="container">
              <div className="content txt-center spacing">
                <div className="row dis-f">
                  <div className="col-md-8 col-sm-11 col-10">
                    <h1>Contact Us</h1>
                    <h2>We are Eager to hear from you!</h2>
                    <p>Want to know more about our services and products? 
                      Drop us your message here.</p>
                    {/* <Link  to={{pathname:'/keyword-demo', aboutProps:{api:'object_detection_api', name:"Object Detection"}}}  className="run-btn">Run Demo</Link> */}
                    {/* <Link  to={{pathname:'/text-demo/Keyword-extraction'}}  className="run-btn demo-btn">Run Demo</Link> */}
                  </div>
                </div>				
                <img src={planetYellow2} alt="Vision lang" width="400" className="planet-pink img-fluid illustration-mob" />
              </div>
              <img src={cldMed} width="200" alt="Vision lang" className="cld-med img-fluid illustration-mob" />
            </div>
          </div>
        </div>
        <img src={ufoO} width="300" alt="Vision lang" className="ufo img-fluid illustration-mob" />
        <div className="container">
          <div className="row dis-f ">
            <div className="col-md-8 col-sm-11 col-10 bg-whit">
              <form action="/action_page.php">
                <label htmlFor="fname">First Name</label>
                <input type="text" id="fname" name="firstname" />

                <label htmlFor="email">Enter your email:</label>
                <input type="email" id="email" name="email" />

                <label htmlFor="subject">Message</label>
                <textarea id="subject" name="subject" ></textarea>

                <input type="submit" value="Submit" className="submit-btn" />
              </form>
            </div>
          </div>
        </div>	
        <img src={planetGreen2} alt="Vision lang" width="400" className="planet_green img-fluid illustration-mob" />
				
     
        <footer className="txt-center bg-twinkle">
          <div className="spacing">			
            <img src={cloudM} alt="Vision lang" className="cloud-m  img-fluid illustration-mob" />	
            <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
          </div>
          <div className="socialmedia dis-f">
            <a href="#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />
        </footer>
      </body>
    );
  }




}
// KeywordExtraction.propTypes = {
//   remove: PropTypes.fun,
// };

// function mapStateToProps(state, ownProps) {
//   // console.log(state) // state
//   // console.log(ownProps) // ownProps
// }

function mapDispatchToProps (dispatch) {
  return {
    remove: (data) => dispatch(ADD_NAVBAR(data)),
  }
}
export default connect(
  null,
  mapDispatchToProps
)(Contact)
