// import React, {useEffect} from 'react';
import React from "react";
// import {useDispatch, useStore} from 'react-redux'
// import { addUser } from '../actions'
import Navbar from "./Nav-bar/navbar";
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons"
import Login from "./Login/login";
import SignUp from "./Signup/signup";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Index from "./homepage/homepage";
import KeywordExtraction from "./Keyword-Detail/keywordDetail";
import ImgDemo from "./img-demo/index";
import ProductOverview from "./Product-Overviews/productOverviews";
import TextDemo from "./Text-Demo/textDemo";
import CrowdDetail from "./crowd-detail/crowd-detail";
import ObjectDetail from "./object-detail/object-detail";
import GenderDetail from "./gender-detail/gender-detail";
import OpticalDetail from "./optical-detail/optical-detail";
import FashionDetail from "./fashion-detail/fashion-detail";
import LprDetail from "./lpr-detail/lpr";
import Contact from "./contact-us/contact-us";
import AboutUs from "./about-us/about-us";
import optocalDemo from "./optocal-demo/optocal-demo";
import "@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css";
library.add(fab, faCheckSquare, faCoffee)

const App = () => {

  // const dispatch = useDispatch()
  // const store = useStore()

  // const name= "Rei"

  // useEffect(() => {
  //   console.log(store)
  //   dispatch(addUser(name))
  // }, [])

  return (
    <BrowserRouter>
      <Navbar/>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Switch>
            <Route exact path='/' component={Index} />
            <Route path="/sign-in" component={Login} />
            <Route path="/keyword-extract" component={KeywordExtraction} />
            <Route path="/demo/:name" component={ImgDemo} />
            <Route path="/product-overview" component={ProductOverview} />
            <Route path="/text-demo/:name" component={TextDemo} />
            <Route path="/crowd-detail" component={CrowdDetail} /> 
            <Route path="/object-detail" component={ObjectDetail} />                      
            <Route path="/gender-detail" component={GenderDetail} />                       
            <Route path="/optical-detail" component={OpticalDetail} />                       
            <Route path="/fashion-detail" component={FashionDetail} />                        
            <Route path="/lpr-detail" component={LprDetail} />                        
            <Route path="/contact-us" component={Contact} />                         
            <Route path="/about-us" component={AboutUs} />                        
            <Route path="/optocal-demo/:name" component={optocalDemo} />          
            <Route path="/sign-up" component={SignUp} />            
          </Switch>
        </div>
      </div>
    </BrowserRouter>

  );
}

export default App;