import React, { Component } from "react";
import "../../style/product-overview.scss"
// import log from "../../utils/assets/bg-log.svg";
// import bgTwinkle from "../../utils/assets/bg-twinkle.png";
import planetGreen from "../../utils/assets/planet-green.svg";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import planetPk from "../../utils/assets/planetPink.svg";
// import plantPnk from "../../utils/assets/planet_pnk.svg";
import keywordPara from "../../utils/assets/keyword-para.svg";
import objectDetect from "../../utils/assets/object-detect.svg";
import genderDetect from "../../utils/assets/gender-detect.svg";
import crowdDensity from "../../utils/assets/crowd-density.svg";
import fashionPro from "../../utils/assets/fashion-pro.svg";
import lprPro from "../../utils/assets/lpr-product.svg";
import rcktSide from "../../utils/assets/rocket.svg";
import ocr from "../../utils/assets/ocr.svg";
// import cldSml from "../../utils/assets/cloud-small.svg";
import planetSky from "../../utils/assets/planet-sky.svg";
import planetYellow from "../../utils/assets/planet-yellow.svg";
import cloudmed from "../../utils/assets/cloud-med.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import cloudBtm from "../../utils/assets/cloud-btm.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import ufoO from "../../utils/assets/ufo-o.svg";
import { Link } from "react-router-dom";



class ProductOverview extends Component {
 
  render() {
    return (
      <body id="product-overview" className="over">
        
        <div className="h-90"></div>
        <div className="hero-section back-twinkle">
          <img src={planetGreen} alt="Vision lang" className="planet-green img-fluid illustration-mob" />
          <img src={planetPk} alt="Vision lang" className="planet-pk img-fluid illustration-mob" />
          <div className="container">
            <img src={cloudBtm} alt="Vision lang" className="cloud-b img-fluid illustration-mob" />
            <div className="content txt-center spacing">
              <div className="row dis-f">
                <div className="col-md-8">
                  <h1>Products</h1>
                  <h2>Here are some of our Advanced AI model ready to help your businesses:</h2>
                </div>
              </div>
            </div>		
          </div>	
        </div>
        <div className="product bg-sky">	
          <div className="spacing-l">
            <div className="bg-twinkle">
              <div className="container">
                <div className="keyword-para spacing space-margin">
                  <div className="row dis-f">
                    <div className="col-md-5 order-md-2">
                      <img src={keywordPara} className="img-fluid" width="375" alt="Vision lang" />
                    </div>
                    <div className="col-md-7 order-md-1  mrgin-right">
                      <h2>Text keyword extraction</h2>
                      <h4>Extract the keywords that matter!</h4>
                      <p>Based on the advanced text analysis AI technique, our tool 
                        helps extract essential keywords, phrases, and expressions 
                        from large texts...</p>     
                      <Link to={{pathname:"/keyword-extract"}} target="_blank"
                        className="run-btn" >Learn More</Link>  
                      <Link to={{pathname:"/text-demo/Keyword-extraction"}} target="_blank"
                        className="run-btn  mrgn-btn" >Run Demo</Link>
                    </div>
                  </div>
                </div>					
                <img src={cloudmed} alt="Vision lang" className="cloud-med img-fluid illustration-mob" />
                <div className="object-detect spacing space-margin">						
                  <img src={planetSky} alt="Vision lang" className="planet-sky img-fluid illustration-mob" />	
                  <div className="row dis-f">
                    <div className="col-md-5">
                      <img src={objectDetect} className="img-fluid" width="375" alt="Vision lang" />
                    </div>
                    <div className="col-md-7 mrgin-left">
                      <h2>Object Detection</h2>
                      <h4>Detect and analyze objects from images and videos</h4>
                      <p>Empower your business with computer-vision object 
                        detection technology by Vision lang. Our state-of-the-art solution 
                        will detect the objects and return the bounding box with 
                        common labels such as chairs, tables, plants, etc. It will help 
                        tag the visual items using AI technology in the fastest way...</p>
                      <Link to={{pathname:"/object-detail"}}  target="_blank"   className="run-btn">Learn More</Link>
                      <Link to={{pathname:"/demo/Object-detection"}}  target="_blank"  className="run-btn  mrgn-btn">Run Demo</Link>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>	
          </div>	
        </div>
        <div className="product bg-purple">
          <div className="spacing-p">
            <div className="bg-twinkle">
              <div className="container">
                <div className="gender-para spacing space-margin">
                  <img src={planetYellow} alt="Vision lang" className="planet-yellow img-fluid illustration-mob" />	
                  <div className="row dis-f">
                    <div className="col-md-5 order-md-2">
                      <img src={genderDetect} className="img-fluid" width="375" alt="Vision lang" />
                    </div>
                    <div className="col-md-7 order-md-1  mrgin-right">
                      <h2>Gender and Age Detection</h2>
                      <h4>Know your customers like never before.</h4>
                      <p>Finding out Who are our target customer&apos;s is the biggest 
                        challenge faced by the marketing astronauts. Here are some 
                        features of this tool that will help you deal...</p>
                      <Link to={{pathname:"/gender-detail"}}  target="_blank"  className="run-btn">Learn More</Link>
                      <Link to={{pathname:"/demo/Gender-age-detection"}}  target="_blank"  
                        className="run-btn mrgn-btn">Run Demo</Link>

                    </div>
                  </div>
                </div>
                <div className="crowd-density spacing space-margin">                  
                  <img src={ufoO} alt="Vision lang" className="ufo img-fluid illustration-mob" />
                  <div className="row dis-f">
                    <div className="col-md-5">
                      <img src={crowdDensity} className="img-fluid" width="375" alt="Vision lang" />
                    </div>
                    <div className="col-md-7 mrgin-left">
                      <h2>Crowd Density Detection</h2>
                      <h4>Estimate crowd size and enhance public safety</h4>
                      <p>Vision lang takes pride in presenting AI-powered Crowd Detection Technology that enables businesses and organizations to automatically detect crowd density. All you need to upload is an image of the crowd in an area, and our system will provide size estimation.</p>
                      <Link to={{pathname:"/crowd-detail"}}  target="_blank"    
                        className="run-btn">Learn More</Link>
                      <Link to={{pathname:"/optocal-demo/Crowd-detection"}}  target="_blank" className="run-btn  mrgn-btn">Run Demo</Link>
                    </div>
                  </div>
                </div>
                <div className="ocr spacing space-margin">
                  <img src={planetGreen} alt="Vision lang" className="p-g img-fluid illustration-mob" />
                  <div className="row dis-f">
                    <div className="col-md-5 order-md-2">
                      <img src={ocr} className="img-fluid" width="375" alt="Vision lang" />
                    </div>
                    <div className="col-md-7 order-md-1 mrgin-right">
                      <h2>Optical Character Recognition (OCR)</h2>
                      <h4>Automatically extract text from images in seconds</h4>
                      <p>Our Optical character recognition (OCR) model will empower you to 
                        extract accurate text and data from the scanned images quickly. Our 
                        experts have trained this model to be effective in a wide range of 
                        areas, from scanning documents...</p>
                      <Link to={{pathname:"/optical-detail"}}  target="_blank"   
                        className="run-btn">Learn More</Link>
                      <Link to={{pathname:"/optocal-demo/OCR"}}  target="_blank" className="run-btn mrgn-btn">Run Demo</Link>
                    </div>
                  </div>                  
                </div>
                <div className="crowd-density bg-twinkle spacing space-margin">
                  <div className="row dis-f">
                    <div className="col-md-5">
                      <img src={fashionPro} className="img-fluid" width="375" alt="Vision lang" />
                    </div>
                    <div className="col-md-7 mrgin-left">
                      <h2>Fashion Detection</h2>
                      <h4>Love an outfit? Upload the image and shop the look instantly</h4>
                      <p>Search for the products without typing a single word. Just one image is enough 
                        for us to know what you are looking for. We are making your fashion discovery fun 
                        and easy with our feature-packed visual search tool...</p>
                      <Link to={{pathname:"/crowd-detail"}}  target="_blank"    
                        className="run-btn">Learn More</Link>
                      <Link to={{pathname:"/demo/Fashion-detection"}}  target="_blank"  className="run-btn  mrgn-btn">Run Demo</Link>
                    </div>
                  </div>
                </div>
                <div className="ocr spacing space-margin">
                  <img src={rcktSide} alt="Vision lang" className="rckt-s img-fluid illustration-mob" />
                  <div className="row dis-f">
                    <div className="col-md-5 order-md-2">
                      <img src={lprPro} className="img-fluid" width="375" alt="Vision lang" />
                    </div>
                    <div className="col-md-7 order-md-1 mrgin-right">
                      <h2>License Plate Recognition</h2>
                      <h4>A perfect solution for enhanced traffic management and security </h4>
                      <p>Automatic License Plate Recognition system by Vision lang is an ideal solution that works accurately and accepts images in all formats. Our team has engineered this solution to offer an optimum solution to identify vehicles by their...</p>
                      <Link to={{pathname:"/lpr-detail"}}  target="_blank"   
                        className="run-btn">Learn More</Link>
                      <Link to={{pathname:"/optocal-demo/LPR"}}  target="_blank" className="run-btn mrgn-btn">Run Demo</Link>
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
            <img src={cloudM} alt="Vision lang" className="cloud-m  img-fluid illustration-mob" />	
          </div>
          <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
        </div>
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">
          </div>
          <div className="socialmedia dis-f">
            <a href="/#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="/#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="/#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="/#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>		
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />
        </footer>
      </body>
    );
  }
}
export default ProductOverview;