import React, { Component } from "react"
import { connect } from "react-redux"
import "../../style/product-detail.scss"
// import log from "../../utils/assets/bg-log.svg";
import planetGreen from "../../utils/assets/planet-green.svg";
import planetPink from "../../utils/assets/planet-pink.svg";
import cldSml from "../../utils/assets/cloud-small.svg";
import cldMed from "../../utils/assets/cloud-med.svg";
import ufoO from "../../utils/assets/ufo-o.svg";

import cloudBtm from "../../utils/assets/cloud-btm.svg";
import planetRing from "../../utils/assets/planet-ring.svg";
import Count from "../../utils/assets/count.svg";
import Product from "../../utils/assets/product.svg";
import Tracking from "../../utils/assets/tracking.svg";
import Security from "../../utils/assets/security.svg";
import cctv from "../../utils/assets/cctv.svg";
import planetGreen2 from "../../utils/assets/planet_green.svg";
import MeteorShoot from "../../utils/assets/meteor-shoot.svg";
import planetary from "../../utils/assets/planetary.svg";
import rocket2 from "../../utils/assets/rocket.svg";
import planetYellow2 from "../../utils/assets/planet-yellow.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import planetBlu from "../../utils/assets/planet_blue.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import shootingStar from "../../utils/assets/shooting.png";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ADD_NAVBAR } from "../../actions"


class ObjectDetail extends Component {

  render() {
    // const { remove } = this.props
    return (
      <div id="product-detail" className="object-detect">        
        <div className="h-90"></div>
        <img src={planetGreen} width="160" alt="Vision lang" className="planet-green img-fluid illustration-mob" />
        <div className="hero-section crowd object-d">
          <div className="bg-sky">
            <div className="container">
              <div className="content txt-center spacing">		
                <img src={planetPink} alt="Vision lang" width="400" className="planet-pink img-fluid illustration-mob" />
                <div className="row dis-f">
                  <h1>Object Detection</h1>
                  <div className="col-md-8 col-sm-12">
                    <h2>Detect and analyze objects from images and videos</h2>
                    <p>Empower your business with computer-vision object detection technology by Vision lang. Our state-of-the-art 
                      solution will detect the objects and return the bounding box with common labels such as chairs, tables, 
                      plants, etc. It will help tag the visual items using AI technology in the fastest way possible. 
                    </p>
                    <p>Our advanced technology understands most of the objects, and it is flexible to be adapted to do anything, 
                      from seeing when eatables in your store are going rotten to identifying security risks.</p>
                    {/* <Link  to={{pathname:'/keyword-demo', aboutProps:{api:'object_detection_api', name:"Object Detection"}}}  className="run-btn">Run Demo</Link> */}
                    <Link  to={{pathname:"/demo/Object-detection"}}  className="run-btn demo-btn">Run Demo</Link>
                  </div>
                </div>		
              </div>
              <img src={cldMed} width="200" alt="Vision lang" className="cld-med img-fluid illustration-mob" />
              <img src={ufoO} width="300" alt="Vision lang" className="ufo img-fluid illustration-mob" />
            </div>
          </div>
        </div>
        <div className="process-section spacing bg-twinkle">
          <div className="product bg-purple">
            <div className="spacing-p">
              <div className="bg-twinkle">                        				
                <img src={planetBlu} alt="Vision lang" className="planet-ring img-fluid illustration-mob" />			
                <img src={MeteorShoot} alt="Vision lang" className="meteor-shoot img-fluid illustration-mob" />	
                <div className="container">
                  <div className="case-title">
                    <h2 className="txt-center">Use Cases and Applications</h2>
                  </div>
                  <div className="internal-bus space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-right dis-f">
                        <img src={Count} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-left">
                        <h4>Counting objects</h4>
                        <p>Be it a retail or manufacturing business, manual 
                          counting of the objects is challenging. Humans can 
                          make errors while working on a large number of 
                          objects. AI-powered object detection tools will help 
                          automatically count the objects and save a 
                          considerable amount of time and money for your 
                          business.</p>
                      </div>
                    </div>
                  </div>
                  <div className="customer space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={Product} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Product classification</h4>
                        <p>The manufacturing units and online retailers can 
                          detect certain products for classification. It will help 
                          them accurately classify and store the products in a 
                          precise manner.</p>
                      </div>
                    </div>
                  </div>
                  <div className="bus-intel space-margin">							
                    <img src={planetRing} alt="Vision lang" className="planet_blue img-fluid illustration-mob" />	
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={Tracking} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6">
                        <h4>Tracking objects</h4>
                        <p>AI-enabled object detection will help track the objects 
                          for making informed decisions. It is useful for moving 
                          objects like a rugby ball, football, movement of cricket 
                          bats, and individuals in the videos. Object/item 
                          detection has various uses in different industries for 
                          surveillance, security, computer vision, etc.</p>
                      </div>
                    </div>
                  </div>
                  <div className="security space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={Security} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Security</h4>
                        <p>Our tool is based on a deep learning framework that 
                          introduces enhanced security in your business at every 
                          level, from logistics to the warehouses.</p>
                      </div>
                    </div>
                  </div>
                  <div className="cctv space-margin">				
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={cctv} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6">
                        <h4>Automated CCTV</h4>
                        <p>Automatic surveillance systems and CCTVs are 
                          based on object detection technology, which helps 
                          them work effectively. In traditional CCTVs, the 
                          video is recorded and saved 24/7, but an 
                          automated CCTV will start recording and saving the 
                          videos only on detecting an object, which helps 
                          save a significant amount of memory. It is also used 
                          for Pedestrian detection in crowded places.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="industries object-detail txt-center bg-twinkle">
          <div className="bg-sky spacing">
            <img src={shootingStar} alt="Vision lang" className="shoot img-fluid illustration-mob" />
            <img src={planetGreen2} alt="Vision lang" className="planet_green img-fluid illustration-mob" />
            <div className="container">
              <h3>Industries that can benefit from our Object Detection Technology</h3>
              <div className="row dis-f no-gutters">
                <div className="col-lg-5 col-md-7 col-10">
                  <a href="#">Politics</a>
                  <a href="#">Retail</a>
                  <a href="#">Healthcare</a>
                  <a href="#">Information Technology (IT)</a>
                  <a href="#">eCommerce</a>
                  <a href="#">Agriculture</a>
                  <a href="#">Manufacturing</a>
                  <a href="#">Object detection via AR/VR</a>
                </div>
              </div>
            </div>			
            <img src={planetary} alt="Vision lang" className="planetary img-fluid illustration-mob" />
          </div>
        </div>
        <div className="pricing txt-center spacing bg-twinkle">
          <div className="container">
            <img src={cldSml} alt="Vision lang" className="cloud-small img-fluid illustration-mob" />
            <img src={rocket2} alt="Vision lang" className="rocket img-fluid illustration-mob" />
            <h2>Pricing</h2>
            <div className="row dis-f">
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 0.00</span>
                    <h5>Basic</h5>
                    <p>10 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4 mrgn">
                <div className="dis-f txt-center">
                  <div className="price-content middle-price">
                    <span>$ 25.00</span>
                    <h5>Advanced</h5>
                    <p>200 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="middle-price txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 100.00</span>
                    <h5>Pro</h5>
                    <p>Unlimited</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
            </div>
          </div>		
          <img src={planetYellow2} alt="Vision lang" className="planet-yellow img-fluid illustration-mob" />
        </div>	
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">			
            <img src={cloudM} alt="Vision lang" className="cloud-m  img-fluid illustration-mob" />	
            <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
          </div>
          <div className="socialmedia dis-f">
            <a href="#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />
        </footer>
      </div>
    );
  }




}
// KeywordExtraction.propTypes = {
//   remove: PropTypes.fun,
// };

// function mapStateToProps(state, ownProps) {
//   // console.log(state) // state
//   // console.log(ownProps) // ownProps
// }

function mapDispatchToProps (dispatch) {
  return {
    remove: (data) => dispatch(ADD_NAVBAR(data)),
  }
}
export default connect(
  null,
  mapDispatchToProps
)(ObjectDetail)
