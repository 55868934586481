import React, { Component } from "react";
import "../../style/keyword-demo.scss"
import { connect } from "react-redux"

// import log from "../../utils/assets/bg-log.svg";
// import bgTwinkle from "../../utils/assets/bg-twinkle.png";
import planetYellow from "../../utils/assets/planet-yellow.svg";
// import imageGallery from "../../utils/assets/image-gallery.svg";
// import gallery from "../../utils/assets/gallery.svg";
import systemUpload from "../../utils/assets/system-upload.svg";
import execute from "../../utils/assets/execute.svg";
import download from "../../utils/assets/download.svg";
import line from "../../utils/assets/line.svg";
import planetG from "../../utils/assets/planet-g.svg";
import rocketBtm from "../../utils/assets/rocket-btm.svg";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import cloudBtm from "../../utils/assets/cloud-btm.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import cldSml from "../../utils/assets/cloud-small.svg";
// import cldMed from "../../utils/assets/cloud-med.svg";
import planetPink from "../../utils/assets/planet-pink.svg";
// import * as ContactsAPI from '../../Contact-api'
import { fetchText } from "../../actions"
import PropTypes from "prop-types";





class TextDemo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      input_text:"",
      output_text:"",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    // this.props.dispatch(fetchProductsSuccess(''))
  }
  static get propTypes() { 
    return { 
      fetchText: PropTypes.any,
      dispatch: PropTypes.any,
      todoList: PropTypes.any,
    }; 
  }



  handleChange(event) {
    this.setState({
      [event.target.name] : event.target.value 
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      output_text : ""
    });
    let body={
      text:this.state.input_text
    }
    this.props.dispatch(fetchText(body))
    console.log(this.props.todoList, "chichichichihc ")

    // if(response){
    //   this.setState({
    //     output_text : JSON.parse(response).data
    //   });
    // }

    // ContactsAPI.text((body)).then((res) => {
    //   // console.log(res)
    //   this.setState({
    //     output_text : res.data
    //   });

    // }).catch((error) => {
    //   if (error.response.status) {
    //     console.log(error)
    //   } else {
    //     console.log(error)
    //   }
    //   console.log(error.message)
    // })
  
    
  }
 
  render() {
    return (
      <div id="object-demo" >
        <div className="h-90"></div>
        <div className="hero-section bg-twinkle">
          <img src={cldSml} alt="Vision lang"  type="image/svg+xml" className="cld-small  img-fluid max-width: 100%; illustration-mob" />
          <div className="container">
            <div className="content txt-center spacing">
              <div className="row dis-f">
                <h1>Text Keyword Extraction</h1>
                <div className="col-md-8">
                  <h2>Extract the keywords that matter!</h2>
                </div>
              </div>				
              <img src={planetYellow} alt="Vision lang" className="planet-yellow img-fluid illustration-mob" />
            </div>
          </div>
        </div>
        <div className="upload-section">
          <div className="bg-twinkle">
            <form className="container" onSubmit={this.handleSubmit}>
              <div className="row" >
                <div className="col-lg-6 images-padding upload-doc">
                  <a href="/#"> </a>
                  <div className="img-gallery">
                    <textarea rows="4" cols="50" placeholder="Your Input will come here" name="input_text" value={this.state.input_text}  onChange={this.handleChange} required></textarea>
                  </div>
                </div>
                <div className="col-lg-6 images-padding download-section">
                  <a href="/#"></a>
                  <div className="img-gallery">
                    <textarea readOnly rows="4" cols="50" placeholder="Your result will come here" name="output_text" value={this.props.todoList}></textarea>

                    {/* <textarea readOnly rows="4" cols="50" placeholder="Your result will come here" name="output_text" value={this.state.output_text}></textarea> */}
                  </div>
                </div>
              </div>
              <div className="button-ex">
                <button  className="run-btn" type="submit">Execute</button>
              </div>
            </form>
          </div>
        </div>
        <div className="keyword-step bg-twinkle spacing txt-center">		
          <img src={planetG} alt="Vision lang" className="planet-g img-fluid illustration-mob" />	
          <img src={planetPink} alt="Vision lang" className="planet-pink img-fluid illustration-mob" />
          <div className="container">
            <h2 className="spacing">How to Extract Keyword</h2>
            <div className="step-execute">
              <div className="step">
                <a href="#"><img src={systemUpload} alt="Vision lang" /></a>
                <div className="step-detail">
                  <h4>Step1</h4>
                  <p>Choose a file from your sytem and upload it</p>
                </div>
              </div>
              <div className="line">
                <img src={line} alt="Vision lang" />
              </div>
              <div className="step side-line">
                <a href="#"><img src={execute} alt="Vision lang" /></a>
                <div className="step-detail">
                  <h4>Step2</h4>
                  <p>Now click on execute button</p>
                </div>
              </div>
              <div className="line">
                <img src={line} alt="Vision lang" />
              </div>
              <div className="step">
                <a href="#"><img src={download} alt="Vision lang" /></a>
                <div className="step-detail">
                  <h4>Step3</h4>
                  <p>You can see your result on screen and download it</p>
                </div>
              </div>
            </div>	
            <div className="button-ex">
              <a href="#" className="run-btn">Watch a video</a>
            </div>	
            <img src={rocketBtm} alt="Vision lang" className="rocket-btm  img-fluid illustration-mob" />
          </div>		
          <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
        </div>
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">
          </div>
          <div className="socialmedia dis-f">
            <a href="/#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="/#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="/#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="/#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />
        </footer>
        
      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   products: state.products.items,
//   loading: state.products.loading,
//   error: state.products.error
// });



function mapStateToProps(state) {
  const { products  } = state
  return { todoList: {products} }
}


export default connect(
  mapStateToProps,
)(TextDemo)