import React, { Component } from "react";
import "../../style/signup.scss"
import log from "../../utils/assets/sign-up.svg";
import {  Link } from "react-router-dom";
import * as ContactsAPI from "../../Contact-api"
import { Redirect } from "react-router-dom";
// import { data } from "jquery";

export default class SignUp extends Component {


  constructor(props) {
    super(props);
    this.state = {
      name:"",
      email:"",
      password:"",
      org_email: "None",
      is_active: true,
      warning_msg:null,
      login: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onShowAlert = ()=>{
    this.setState({visible:true},()=>{
      window.setTimeout(()=>{
        this.setState({warning_msg:null})
      },12000)
    });
  }


  handleChange(event) {
    this.setState({
      [event.target.name] : event.target.value 
    });
  }

  handleSubmit(event) {
    // let history = useHistory();
    event.preventDefault();
    const payload = (({ name, email, password, org_email, is_active }) => ({  name, email, password, org_email, is_active }))(this.state);

    ContactsAPI.register((payload)).then((res) => {
      console.log(res)
      if (res.data.message==="User already exists"){
        this.onShowAlert();
        this.setState({warning_msg:res.data.message})
      }
      if (res.status== 200 && res.data.access_token){

        this.setState({login:true})

      }
    }
    ).catch((error) => {
      if (error.response.status) {
        console.log(error)
      } else {
        console.log(error)
      }
      this.onShowAlert();
      this.setState({warning_msg:error.message})
    })
    
  }
  render() {
    let { warning_msg, login } = this.state
    if (login) {
      return <Redirect to='/sign-in' />
    }
    return (
      <div id="signup">
        <div className="h-60"></div>
        <div className="login">
          <div className="login-form">
            <div className="row no-gutters spacing">
              <div className="col-md-7 col-10">
                <div className="row no-gutters inner-log">	
                  <div className="col-md-7">
                    <img src={log} alt="astronaut" className="img-fluid"></img>
                  </div>	
                  <div className="col-md-5">
                    <div className="right-section">
                      <h2>Create Account</h2>
                      <form onSubmit={this.handleSubmit}> 
                        {/* <input type="text"      placeholder="Your name"    value={this.state.value}        onChange={this.handleChange} /> */}
                        <input type="text"      placeholder="Your name"   name="name" value={this.state.name}        onChange={this.handleChange} required/>
                        <input type="email"     placeholder="Enter email"  name="email"  value={this.state.email}    onChange={this.handleChange} required/>
                        <input type="password"  placeholder="Password"  name="password" value={this.state.password}  onChange={this.handleChange} required/>
                        {/* <input type="password" placeholder="Confirm password" name="psw" required  value={this.state.value} onChange={this.handleChange} /> */}
                        <div className="remember">
                          <label>
                            <input type="checkbox" defaultChecked name="remember" /> <a > I agree to all statemnt to Terms of service</a>
                          </label>
                        </div>
                        <button type="submit">Create Account</button>
                      </form>
                      <h6 >Already have an account?  <Link to={"/sign-in"} className="lgn-route">Login</Link></h6>
                    </div>
                    {warning_msg &&
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                      <strong>{warning_msg}</strong>
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  }
}