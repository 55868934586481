import axios from "axios";
export const ADD_USER = "ADD_USER"
export const REMOVE_USER = "REMOVE_USER"
export const ADD_NAVBAR = "ADD_NAVBAR"
export const FETCH_PRODUCTS_BEGIN   = "FETCH_PRODUCTS_BEGIN";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
/* eslint-env node */

// import fs from 'fs'

// my code
const api = process.env.REACT_APP_API_URL
const headers = {
  "accept": "application/json",
  "Acces-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true,
}

const fetchProductsBegin = () => ({
  type: FETCH_PRODUCTS_BEGIN
});

const fetchProductsSuccess = res => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload:  res,
  status: res.status
});

const fetchProductsFailure = error => ({
  type: FETCH_PRODUCTS_FAILURE,
  payload: { error }
});

const addUser = (name, auth) => ({
  type: ADD_USER,
  name,
  auth
});
const addNavbar = (navbar, path) => ({
  type: ADD_NAVBAR,
  navbar,
  path
});


const removeUser = () => ({
  type: REMOVE_USER,

});

function fetchProducts() {
  return dispatch => {
    dispatch(fetchProductsBegin());
    return fetch("/products")
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        console.log(json)
        dispatch(fetchProductsSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchProductsFailure(error)));
  };
  
}

const fetchText  = (body) => {
  return dispatch => {
    dispatch(fetchProductsBegin());
    axios({
      method: "post",
      url: `${api}/text/`,
      data: body,
      headers:{...headers,  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}
    })
      .then(res => {
        dispatch(fetchProductsSuccess(res));
      })
      .catch(error => dispatch(fetchProductsFailure(error)));

  }
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export   {
  addUser, removeUser,addNavbar, fetchProductsFailure, fetchProductsSuccess, fetchProductsBegin, fetchProducts, fetchText
};