/* eslint-disable react/display-name */
import React,{useState,useEffect} from "react";
import "../../style/chart.scss"
import {
  ArgumentAxis,
  ValueAxis,
  Title,
  Chart,
  LineSeries,
} from "@devexpress/dx-react-chart-bootstrap4";
import "@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css";
import PropTypes from "prop-types";




const LChart = (props) => {
  let [data,setItems] = useState(props.data);
  
  // const [count,setCount] = useState(0);
  
  
  useEffect(() => {
    setItems(props.data);
  }, [props.data])

  // useEffect(() => {
  //   setCount(data[0].value);
  // }, [props.data[0].value])


  return (
    <div className="container">
      {/* <h1 className="card-clr text-center">count:{count}</h1> */}
      <div className="card row">
        <div className="col-1 text-center  card-clr" style={{  writingMode:"vertical-lr"}}>count</div>
        <div className="col-10">
          <Chart
            height="300"
            data={data}
          >

            <ArgumentAxis  color=" #DA50EB"/>
            <ValueAxis />

            <LineSeries name="test" valueField="value" argumentField="argument" color=" #DA50EB"	 height="43"/>
            <Title text="check" />

          </Chart>
          <div className=" text-center row card-clr"><p>Prople</p></div>
        </div>
      </div>
    </div>
  );
}
LChart.propTypes = {
  data: PropTypes.any
}

export default LChart;