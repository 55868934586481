/* eslint-disable default-case */
import React, { Component } from "react";
import "../../style/keyword-demo.scss"
// import log from "../../utils/assets/bg-log.svg";
// import bgTwinkle from "../../utils/assets/bg-twinkle.png";
import planetYellow from "../../utils/assets/planet-yellow.svg";
import imageGallery from "../../utils/assets/image-gallery.svg";
// import gallery from "../../utils/assets/gallery.svg";
import systemUpload from "../../utils/assets/system-upload.svg";
import execute from "../../utils/assets/execute.svg";
import download from "../../utils/assets/download.svg";
import line from "../../utils/assets/line.svg";
import planetG from "../../utils/assets/planet-g.svg";
import rocketBtm from "../../utils/assets/rocket-btm.svg";
import rocket from "../../utils/rocket.gif";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import cloudBtm from "../../utils/assets/cloud-btm.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
// import demo_ocr_1 from "../../utils/demo_ocr_1.jpg";
// import demo_ocr_2 from "../../utils/demo_ocr_2.jpg";
// import demo_ocr_3 from "../../utils/demo_ocr_3.jpg";
// import demo_ocr_0 from "../../utils/demo_ocr_0.jpg";
// import demo_ocr_4 from "../../utils/demo_ocr_4.jpg";
import cldSml from "../../utils/assets/cloud-small.svg";
// import cldMed from "../../utils/assets/cloud-med.svg";
import planetPink from "../../utils/assets/planet-pink.svg";
import * as ContactsAPI from "../../Contact-api"
import PropTypes from "prop-types";
import Demo from "../Chart";



class optocalDemo extends Component {

  constructor(props){
    super(props)
    this.state = {
      url: imageGallery,
      bool: false,
      output_text:"",
      formData:null,
      count:0,
      carousel:true,
      data:[{ argument: 1, value: 0 },
        { argument: 2, value: 0 },]
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.demo = this.demo.bind(this)

  }

  static get propTypes() { 
    return { 
      match: PropTypes.any, 
      aboutProps: PropTypes.func 
    }; 
  }

  incrementCount= () => {
    this.setState({
      carousel:false,
      count:this.state.count+1
    })
    
  }
  
  decrementCount= () => {
    this.setState({
      carousel:false,
      count:this.state.count-1
    })
  }

  handleChange(e) {
    // eslint-disable-next-line no-unused-expressions
    this.state.data[1]
    this.setState({
      output_text : "",
      carousel:true

    });
    let file = e.target.files[0];

    let formData = new FormData();
    this.setState({
      url:URL.createObjectURL(file),
      bool: false,
    })  
    formData.append("file", file);
    this.setState({
      formData
    }) 
    

  }
  demo(id) {
    switch (id) {
    case 0:
      this.setState({
        output_text:""
      }) 
      break;
    case 1:
      this.setState({
        output_text:`ke CONFIDENCIAL
        ee. FORMATO DE INSPECCION DE CONTENEDORES \n
        eal\n
        Lugar de Inspeccion:~
        \n
        ae mm vam
        \n
        PTAAIZ. INT. 
        \n
        PRD.IZ.INT.\n
        ESPACIADORES\n
        \n
        PRD.FR.INT.\n
        DEFLECTOR\n
        TECHO INT.\n
        PISO INT.\n
        PRD.DER.INT.\n
        \n
        Ue\n
        \n
        PTA.DER.INT.\n
        EVAPORADORES\n
        PISO EXT.\n
        TECHO EXT.\n
        PRD.IZ.EXT.\n
        \n
        14] PDR.DER.EXT.\n
        15] — PTAS.EXT.\n
        \n
        16| TORN.SGD.\n
        \n
        " SRFO3531 gD sREO2212 =\n
        \n\n\n
         
        
        PORN DET She EDAD DE RISK PROTECTION RISKPRO S.A.\n
        PROHIBIDO SU REPRODUCCION TOTAL O PARCIAL\n
        ‘CONFIDENCIAL\n
        
         
        `
      }) 
      break;
    case 2:
      this.setState({
        output_text:"MMAU \n 104 269 0"
      }) 
      break;
    case 3:
      this.setState({
        output_text:"SECURITY INSPECTION \n RISK \n PROTECTION \n SELLO # SR2161848A"

      }) 
      break;
    case 4:
      this.setState({
        output_text:"\n RISK PROTECTION \n\n\n RPE183683 \n H"

      }) 
      break;
    case 5:
      this.setState({
        output_text:"EMPTY CONTAINER \n RISK \n PROTECTION \n SREO2212 \n\n FULL CONTAINER \n RISK \n PROTECTION \n SRF03531"

      }) 
    }
    
  }

  handleSubmit() {
    this.demo(0)

    if(this.state.carousel){
      this.setState({
        bool:true
      })
      let api = null;
      let {formData} = this.state;
      if(this.props.match.params.name==="OCR"){
        api = "tesseract"
        ContactsAPI.UploadFilesService(api,formData).then((res) => {
          this.setState({
            bool:false,
            output_text : res.data.text
          });
        }).catch((error) => {
          console.log(error)
          return error;
        }
        )
      }
      if(this.props.match.params.name==="LPR"){
        api = "license_plate"
        ContactsAPI.UploadFilesService(api,formData).then((res) => {
          console.log(res.data.license)
          this.setState({
            bool:false,
            output_text : res.data.license
          });
        }).catch((error) => {
          console.log(error)
          return error;
        }
        )
      }
      
      if(this.props.match.params.name==="Crowd-detection"){
        api = "crowd"
  
        ContactsAPI.UploadFilesService(api,formData).then((res) => {
          this.setState(({data}) => ({
            bool:false,
            data: [
              ...data.slice(0,1),
              {
                ...data[1],
                value: res.data.count,
              },
              ...data.slice(2)
            ]
          }));
          
        }).catch((error) => {
          
          return(error)
        }
        )
      }

    }else{
      this.setState({
        bool:true
      })
      setTimeout(
        () => {this.setState({ bool: false});     this.demo(this.state.count)
        }, 
        3000
      );
    }
  }


  render() {
    let {url, data, count, carousel, bool} = this.state;
    let {name} = this.props.match.params
    console.log(url)
    return (
      <div id="object-demo" className="ocr">        
        <div className="h-90">
          { (bool)  && 
          <div className="modal-content gif_default"> 
            <img  className="gif col-md-5 " src={rocket}   alt="loading..." />
          </div>
          }
        </div>
        <div className="hero-section bg-twinkle">
          <img src={cldSml} alt="Vision lang" className="cld-small img-fluid illustration-mob" />
          <div className="container">
            <div className="content txt-center spacing">
              <div className="row dis-f">
                <div className="col-md-8">
                  {name==="OCR" && <div><h1>Optical Character Recognition</h1>    <h2>Automatically extract text from the image in seconds</h2></div>}
                  {name==="Crowd-detection" && <div> <h1>Crowd Count</h1> <h2>Automatically extract text from the image in seconds</h2> </div>}
                  {name==="LPR" && <div> <h1>licence plate recognition</h1> <h2>Automatically extract licence plate number from the image in seconds</h2> </div>}
                </div>
              </div>				
              <img src={planetYellow} alt="Vision lang" className="planet-yellow img-fluid illustration-mob" />
            </div>
          </div>
        </div>
        <div className="upload-section">
          <div className="bg-twinkle">
            <div className="container">
              <div className="row"  >
                <div className="col-lg-6 images-padding">
                  <input type='file' onChange={this.handleChange} className="upload" />
                  <div className="img-gallery car">
                    {name!=="OCR" && 
                    <img className="d-block" src={url} alt="First slide"/>
                    }
                    {(name==="OCR" || name ==="LPR")  && 
                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel" data-interval="false">
                      <ol className="carousel-indicators">
                        {/* <li data-target="#carouselExampleIndicators" data-slide-to={`${count}`}  className="active"></li> */}
                        
                      </ol>
                      {name==="OCR" && 
                      <div>
                        {carousel &&
                          <div className="carousel-item active">
                            <img className="d-block" src={url} alt="first"/>
                            <div className="carousel-caption d-none d-md-block">
                              <h5 className="text-primary">Upload file or click `&gt;` to scroll left</h5>
                            </div>
                          </div>
                        }
                        {!carousel &&

                          <div className="carousel-item active ">
                            {/* <img className="d-block" src={require(`../../utils/1.jpg`)} alt="First slide"  /> */}
                            <img className="d-block" src={require(`../../utils/OCR/${count}.jpg`).default} alt={`${count}`}  />
                            {/* onClick={() => this.demo(0)} */}
                          </div>
                        }
                          
                      </div>
                      }
                      
                      
                    </div>
                    }
                    {name==="OCR" && 
                    <div>
                      {count>=1 && 

                      <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon"  onClick={this.decrementCount}  aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      }
                      {count<5 &&

                      <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon"  onClick={this.incrementCount} aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                      </a>
                      }
                    </div>
                    
                    }
                  </div>
                </div>
                <div className="col-lg-6 images-padding download-section">
                  <a href="#">Download</a>
                  <div className="img-gallery">
                    {(name==="OCR" || name==="LPR") &&  <textarea readOnly rows="4" cols="50" placeholder="Your result will come here" name="output_text" value={this.state.output_text}></textarea>
                    }
                    {name==="Crowd-detection" &&  <Demo  data={data}/>}

                  </div>
                </div>
              </div>
              <div className="button-ex">
                <button onClick={this.handleSubmit} className="run-btn">Execute</button>
              </div>
            </div>
          </div>
        </div>
        <div className="keyword-step bg-twinkle spacing txt-center">		
          <img src={planetG} alt="Vision lang" className="planet-g img-fluid illustration-mob" />	
          <img src={planetPink} alt="Vision lang" className="planet-pink img-fluid illustration-mob" />
          <div className="container">
            <h2 className="spacing">How to Extract Keyword</h2>
            <div className="step-execute">
              <div className="step">
                <a href="#"><img src={systemUpload} alt="Vision lang" /></a>
                <div className="step-detail">
                  <h4>Step1</h4>
                  <p>Choose a file from your sytem and upload it</p>
                </div>
              </div>
              <div className="line">
                <img src={line} alt="Vision lang" />
              </div>
              <div className="step side-line">
                <a href="#"><img src={execute} alt="Vision lang" /></a>
                <div className="step-detail">
                  <h4>Step2</h4>
                  <p>Now click on execute button</p>
                </div>
              </div>
              <div className="line">
                <img src={line} alt="Vision lang" />
              </div>
              <div className="step">
                <a href="#"><img src={download} alt="Vision lang" /></a>
                <div className="step-detail">
                  <h4>Step3</h4>
                  <p>You can see your result on screen and download it</p>
                </div>
              </div>
            </div>	
            <div className="button-ex">
              <a href="#" className="run-btn">Watch a video</a>
            </div>	
            <img src={rocketBtm} alt="Vision lang" className="rocket-btm  img-fluid illustration-mob" />
            <img src={cloudM} alt="Vision lang" className="cloud-m  img-fluid illustration-mob" />	
          </div>		
          <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
        </div>
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">
          </div>
          <div className="socialmedia dis-f">
            <a href="/#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="/#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="/#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="/#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />
        </footer>
        
      </div>
    );
  }
}
export default optocalDemo;