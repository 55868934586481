
// import ScriptTag from 'react-script-tag';
import * as $ from "jquery";

import React, { Component } from "react";
import { connect } from "react-redux";
import "../../style/homepage.scss";
// import log from "../../utils/assets/bg-log.svg";
// import bgTwinkle from "../../utils/assets/bg-twinkle.png";
// import planetYellow from "../../utils/assets/planet-yellow.svg";
import planetYellow2 from "../../utils/assets/planet-yellow2.svg";
import circle1 from "../../utils/assets/circle-01.png";
import circle2 from "../../utils/assets/circle-02.png";
import circle3 from "../../utils/assets/circle-03.png";
import circle4 from "../../utils/assets/circle-04.png";
import ufoO from "../../utils/assets/ufo-o.svg";
import aiLogo from "../../utils/assets/ai-img.png";
import fashionPro from "../../utils/assets/fashion-pro.svg";
import ia from "../../utils/assets/ia.png";
import vr from "../../utils/assets/vr.png";
import nlp from "../../utils/assets/nlp.png";
// import imageGallery from "../../utils/assets/image-gallery.svg";
// import gallery from "../../utils/assets/gallery.svg";
// import systemUpload from "../../utils/assets/system-upload.svg";
// import execute from "../../utils/assets/execute.svg";
import gif from "../../utils/assets/1.png";
// import download from "../../utils/assets/download.svg";
// import line from "../../utils/assets/line.svg";
// import planetG from "../../utils/assets/planet-g.svg";
// import rocketBtm from "../../utils/assets/rocket-btm.svg";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import planetRing from "../../utils/assets/planet-ring.svg";
import planetGRN from "../../utils/assets/planet-green.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import cloudMed from "../../utils/assets/cloud-med.svg";
import cloudBtm from "../../utils/assets/cloud-btm.svg";
import astronaut from "../../utils/assets/astronaut.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import cldSml from "../../utils/assets/cloud-small.svg";
import cldB from "../../utils/assets/cld-b.png";
import keywordPara from "../../utils/assets/keyword-para.svg";
import objectDetect from "../../utils/assets/object-detect.svg";
import genderDetect from "../../utils/assets/gender-detect.svg";
import ocr from "../../utils/assets/ocr.svg";
import crowdDensity from "../../utils/assets/crowd-density.svg";
import state from "../../utils/assets/sate.png";
import machineLearn from "../../utils/assets/machine-learning.png";
// import demo_out1 from "../../utils/demo_out.jpg";
// import demo_in1 from "../../utils/demo_in.jpg";
import { Link } from "react-router-dom";
// import rocket from "../../utils/rocket.gif";     
// import planetPink from "../../utils/assets/planet-pink.svg";
import planetGreen2 from "../../utils/assets/planet_green.svg";
import planetBlu from "../../utils/assets/planet-blue.svg";
import astSq2 from "../../utils/assets/asteroid-sq2.png";
// import * as ContactsAPI from '../../Contact-api'
// import PropTypes from "prop-types";
// import in_1 from "../../utils/1.jpg";
// import out_1 from "../../utils/1_out.jpg";
// import in_2 from "../../utils/3.jpg";
// import out_2 from "../../utils/3_out.jpg";
// import in_3 from "../../utils/4.jpg";
// import out_3 from "../../utils/4_out.jpg";
import { ADD_NAVBAR } from "../../actions"
import "paroller.js";
// eslint-disable-next-line no-undef
// import { Link } from "react-router-dom";

class homePage extends Component {

  componentDidMount () {

    try {
      $(".my-paroller").paroller();      
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    // const { remove } = this.props
    return (
      <div id="product-overview" className="homepage home-style">
        <div className="hero-section">
          <div id='stars'></div>
          <div id='stars2'></div>
          <div id='stars3'></div>
          <div className="container">
            <div className="row ">
              <div className="col-lg-7 order-lg-2 dis-f">
                <div className="ai-text">A.I.</div>
                <div className="circle-01"><img src={circle1} alt="Vision lang" className="img-fluid" /></div>
                <div className="circle-02"><img src={circle2} alt="Vision lang" className="img-fluid" /></div>
                <div className="circle-03"><img src={circle3} alt="Vision lang" className="img-fluid" /></div>
                <div className="circle-04"><img src={circle4} alt="Vision lang" className="img-fluid" /></div>
                <div className="rotating">    
                  <img src={aiLogo} alt="Vision lang" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-5 d-flex align-items-center order-lg-1">
                <div className="hero-text">
                  <h1>Vision lang -</h1>
                  <h4>Your Gateway To The Universe
                    Of Business Growth Opportunities</h4>
                  <p>Discover opportunities, grow customer reach, and skyrocket your sales without losing your chill Vision lang empowers growth-seeking businesses to leverage the potential of AI. We provide advanced AI tools to start an innovative business journey.</p>
                  <Link to={{pathname:"/product-overview"}} target="_blank" className="run-btn" >See all Services</Link>
                </div>
              </div>
            </div>
          </div>	
        </div>
        <div className="ai-universe industries shooting txt-center">
          <div className="bg-sky spacing">
            <img src={cloudMed} alt="Vision lang" className="cld-med img-fluid my-paroller" 
              data-paroller-factor="-0.1" data-paroller-type="foreground" 
              data-paroller-direction="vertical" />
            <img src={planetPnk} alt="Vision lang" className="plntpnk img-fluid my-paroller" data-paroller-factor="-0.3" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <img src={planetGreen2} alt="Vision lang" className="plntgrn img-fluid my-paroller" data-paroller-factor="-0.3" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <img src={planetYellow2} alt="Vision lang" className="plntylw img-fluid my-paroller" data-paroller-factor="0.3" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <img src={cloudBtm} alt="Vision lang" className="cldm img-fluid my-paroller" data-paroller-factor="0.3" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <img src={astronaut} alt="Vision lang" className="astr img-fluid my-paroller" data-paroller-factor="0.5" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <div className="bg-twinkle">
              <div className="container spacing">
                <h2>But First,</h2>
                <h4>let&apos;s talk about your trip to the AI universe!</h4>
                <h6>If you have landed here, you&apos;re likely on a hunt for the best AI tools that bring agility to your<br></br> end to end business tasks. We bet you are looking to incorporate AI for:</h6>
                <div className="row dis-f no-gutters">
                  <div className="col-md-5 col-10">
                    <a href="#">Improving your business operations</a>
                    <a href="#">Skyrocket brand growth</a>
                    <a href="#">Automate day to day tasks</a>
                    <a href="#">Making data-driven business decisions</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="panic-section industries txt-center spacing">
          <img src={state} alt="Vision lang" className="sate img-fluid my-paroller" data-paroller-factor="0.5" data-paroller-type="foreground" data-paroller-direction="vertical" />
          <img src={cloudMed} alt="Vision lang" className="cld-me fth img-fluid my-paroller" 
            data-paroller-factor="0.2" data-paroller-type="foreground" 
            data-paroller-direction="vertical" />
          <div className="container spacing">
            <h2>Don&apos;t Panic</h2>
            <h4>Team Vision lang has got you covered.</h4>
            <h6>Innovative technologies that keep us excited all the time…</h6>
            <div className="row no-gutters spacing">
              <div className="col-md-3 col-sm-6">
                <div className="panic-cat">						
                  <img src={machineLearn} alt="Vision lang" width="128" className="img-fluid" />
                  <p>Artificial Intelligence<br></br> (ML, deep learning)</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="panic-cat">						
                  <img src={vr} alt="Vision lang" width="120" className="img-fluid" />
                  <p>Mixed Reality<br></br> /AR+VR</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="panic-cat">						
                  <img src={nlp} alt="Vision lang" width="120" className="img-fluid" />
                  <p>Natural Language<br></br> Processing</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="panic-cat">						
                  <img src={ia} alt="Vision lang" width="120" className="img-fluid" />
                  <p>Integration and<br></br> Automation</p>
                </div>
              </div>
            </div>			
            <img src={cloudMed} alt="Vision lang" className="cld-me img-fluid my-paroller" data-paroller-factor="0.5" data-paroller-type="foreground" data-paroller-direction="vertical" />
          </div>
        </div>
        <div className="product wondering">	
          <div className="">			
            <img src={planetRing} alt="Vision lang" className="plnrng img-fluid" />
            <img src={planetGRN} alt="Vision lang" className="plng img-fluid my-paroller" data-paroller-factor="-0.2" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <img src={planetPnk} alt="Vision lang" className="plnpnk img-fluid my-paroller" data-paroller-factor="0.4" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <img src={planetBlu} alt="Vision lang" className="plnblu img-fluid my-paroller" data-paroller-factor="-0.1" data-paroller-type="foreground" data-paroller-direction="horizontal" />
            <img src={cldSml} alt="Vision lang" className="c-small img-fluid my-paroller" data-paroller-factor="-0.1" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <img src={cloudMed} alt="Vision lang" className="c-med img-fluid my-paroller" data-paroller-factor="0.3" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <img src={astSq2} alt="Vision lang" className="sq2 img-fluid my-paroller" data-paroller-factor="0.3" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <img src={cloudM} alt="Vision lang" className="c-m img-fluid my-paroller" data-paroller-factor="0.3" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <img src={cloudBtm} alt="Vision lang" className="c-b img-fluid my-paroller" data-paroller-factor="0.3" data-paroller-type="foreground" data-paroller-direction="vertical" />
            <div className="bg-twin">
              <div className="txt-center">
                <h2>Are you wondering</h2>
                <h4>How we can help you?</h4>
                <h6>Alright, here are some of our Advanced AI model ready to help your businesses:</h6>
              </div>
              <div className="spacing-l">
                <div className="feaures">
                  <img src={cloudM} alt="Vision lang" className="c-m pro img-fluid my-paroller" data-paroller-factor="0.3" data-paroller-type="foreground" data-paroller-direction="vertical" />
                  <div className="container">    
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="product-box">
                          <div className="image-icn">
                            <img src={keywordPara} width="350" className="img-fluid" alt="Vision lang" />
                          </div>
                          <div className="prod-txt">
                            <h3>Text keyword extraction tool</h3>
                            <p className="home-dismob">Extract the right information based on the advanced text analysis...</p>                               
                            <Link to={{pathname:"/keyword-extract"}} target="_blank" className="run-btn" >Try Yourself</Link> 
                            <Link to={{pathname:"/text-demo/Keyword-extraction"}} target="_blank" className="run-btn" >Run Demo</Link>
                          </div>
                        </div>
                      </div>
    
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="product-box">
                          <div className="image-icn">
                            <img src={objectDetect} width="350" className="img-fluid" alt="Vision lang" />
                          </div>
                          <div className="prod-txt">
                            <h3>Object detection tool</h3>
                            <p className="home-dismob">We have created the AI-powered object detection system...</p>
                            <Link to={{pathname:"/object-detail"}}  target="_blank"   className="run-btn">Try Yourself</Link>
                            <Link to={{pathname:"/demo/Object-detection"}}  target="_blank"  className="run-btn">Run Demo</Link>
                          </div>
                        </div>
                      </div>
    
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="product-box">
                          <div className="image-icn">
                            <img src={genderDetect} width="350" className="img-fluid" alt="Vision lang" />
                          </div>                          
                          <div className="prod-txt">
                            <h3>Gender and Age detection tool</h3>
                            <p className="home-dismob">Expand your business horizons by knowing who your target customers are...</p>
                            <Link to={{pathname:"/gender-detail"}}  target="_blank"  className="run-btn">Try Yourself</Link>
                            <Link to={{pathname:"/demo/Gender-age-detection"}}  target="_blank" className="run-btn">Run Demo</Link>
                          </div>
                        </div>
                      </div>
    
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="product-box">
                          <div className="image-icn">
                            <img src={crowdDensity} width="350" className="img-fluid" alt="Vision lang" />
                          </div>
                          <div className="prod-txt">
                            <h3>Crowd density detection</h3>
                            <p className="home-dismob">From crowd size estimation for events, managing risks, the safety...</p>
                            <Link to={{pathname:"/crowd-detail"}}  target="_blank" className="run-btn">Try Yourself</Link>
                            <Link to={{pathname:"/optocal-demo/Crowd-detection"}}  target="_blank" className="run-btn">Run Demo</Link>
                          </div>
                        </div>
                      </div>
    
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="product-box">
                          <div className="image-icn">
                            <img src={ocr} width="350" className="img-fluid" alt="Vision lang" />
                          </div>                          
                          <div className="prod-txt">
                            <h3>Optical Character Recognition (OCR)</h3>
                            <p className="home-dismob">Detect, understand, extract information from the scanned images quickly...</p>
                            <Link to={{pathname:"/optical-detail"}}  target="_blank" className="run-btn">Try Yourself</Link>
                            <Link to={{pathname:"/optocal-demo/OCR"}}  target="_blank" className="run-btn">Run Demo</Link>
                          </div>
                        </div>
                      </div>
    
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="product-box">
                          <div className="image-icn">
                            <img src={fashionPro} width="350" className="img-fluid" alt="Vision lang" />
                          </div>                          
                          <div className="prod-txt">
                            <h3>Fashion Detection</h3>
                            <p className="home-dismob">Upload the image and shop the look instantly without typing a single word...</p>
                            <Link to={{pathname:"/crowd-detail"}}  target="_blank" className="run-btn">Try Yourself</Link>
                            <Link to={{pathname:"/demo/Fashion-detection"}}  target="_blank"  className="run-btn rn-d">Run Demo</Link>
                          </div>
                        </div>
                      </div>
    
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="product-box">
                          <div className="image-icn">
                            <img src={objectDetect} width="350" className="img-fluid" alt="Vision lang" />
                          </div>
                          <div className="prod-txt">
                            <h3>License Plate Recognition</h3>
                            <p className="home-dismob">an ideal solution that works accurately and accepts images in all formats...</p>
                            <Link to={{pathname:"/lpr-detail"}}  target="_blank" className="run-btn">Try Yourself</Link>
                            <Link to={{pathname:"/optocal-demo/LPR"}}  target="_blank" className="run-btn">Run Demo</Link>
                          </div>
                        </div>
                      </div>    
                    </div>
                  </div>
                </div>	
              </div>	
            </div>
          </div> 
        </div>
        <footer className="txt-center spacing bg-twinkle">	
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid" />
          <img src={cldSml} alt="Vision lang" className="c-small img-fluid my-paroller" data-paroller-factor="0.2" data-paroller-type="foreground" data-paroller-direction="horizontal" />
          <img src={cldB} alt="Vision lang" className="c-b img-fluid my-paroller" data-paroller-factor="-0.3" data-paroller-type="foreground" data-paroller-direction="horizontal" />
          <img src={cloudBtm} alt="Vision lang" className="c-btm img-fluid" />
          <img src={ufoO} alt="Vision lang" className="ufo img-fluid my-paroller" data-paroller-factor="0.2" data-paroller-type="foreground" data-paroller-direction="horizontal" />	
          <img src={planetPnk} alt="Vision lang" className="p-pnk img-fluid my-paroller" data-paroller-factor="0.3" data-paroller-type="foreground" data-paroller-direction="vertical" />	
          <div className="spacing hide-in-mob">	
          </div>
          <div className="footer-txt txt-center">			
            <img src={gif} alt="Vision lang" width="380" className="img-fluid" />
            <h3>Is your business all set<br></br>to enroll in our AI universe?</h3>	
            <a href="#" className="run-btn demo-btn">Let&apos;s Connect</a>
          </div>
          <div className="spacing">	
          </div>
          <div className="socialmedia dis-f">                 
            <a href="#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>	
        </footer>
      </div>
    );
  }
}
// KeywordExtraction.propTypes = {
//   remove: PropTypes.fun,
// };

// function mapStateToProps(state, ownProps) {
//   // console.log(state) // state
//   // console.log(ownProps) // ownProps
// }

function mapDispatchToProps (dispatch) {
  return {
    remove: (data) => dispatch(ADD_NAVBAR(data)),
  }
}
export default connect(
  null,
  mapDispatchToProps
)(homePage)