import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../style/navbar.scss"
import { connect } from "react-redux"
import PropTypes from "prop-types";
// import logo from "../../utils/logo-2.png"
import { removeUser } from "../../actions"


class Navbar extends Component { 

  static get propTypes() { 
    return { 
      rmuser: PropTypes.any,
      todoList:PropTypes.any
    }; 
  }
  componentDidMount(){
    // console.log(this)
    // console.log(this.props.todoList.user.auth, "here")
    // if(this.props.todoList.user.auth){
    //   this.setState({login:true})
    // }
  }

  logout=()=>{
    // this.setState({login:false})
    this.props.rmuser()
  }

  render(){
    // let { login } = this.state
    return (
      <div className="">
        <nav className="navbar  navbar-expand-lg navbar-light "  >
          <meta name="apple-mobile-web-app-capable" content="yes"></meta>
          <meta name="apple-mobile-web-app-status-bar-style" content="green"></meta>
          <meta name="apple-mobile-web-app-title" content="Vision lang"></meta>
          <link rel="apple-touch-icon" href="/assets/images/icons/icon-72x72.png" sizes="72x72"></link>
          <link rel="apple-touch-icon" href="/assets/images/icons/icon-96x96.png" sizes="96x96"></link>
          <link rel="apple-touch-icon" href="/assets/images/icons/icon-128x128.png" sizes="128x128"></link>
          <link rel="apple-touch-icon" href="/assets/images/icons/icon-144x144.png" sizes="144x144"></link>
          <link rel="apple-touch-icon" href="/assets/images/icons/icon-152x152.png" sizes="152x152"></link>
          <link rel="apple-touch-icon" href="/assets/images/icons/icon-192x192.png" sizes="192x192"></link>
          <link rel="apple-touch-icon" href="/assets/images/icons/icon-384x384.png" sizes="384x384"></link>
          <link rel="apple-touch-icon" href="/assets/images/icons/icon-512x512.png" sizes="512x512"></link>
          <meta name="Vision lang" content="ai ml"></meta>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <meta name="Vision lang" content="ai ml"></meta>

          <div className="collapse navbar-collapse text-center justify-content-center " id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0 text-capitalize ">
              {/* <a className="navbar-brand " href="#/"><img  alt="img"  src ={logo} rel="preload" as="image"></img></a> */}
            </ul>
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0 text-capitalize navigation">
              <li className="nav-item active ">
                <Link  to={"/"} className="nav-link text-white">Home<span className="sr-only"></span></Link>
              </li>
              <meta name="Vision lang" content="ai ml"></meta>

              <li className="nav-item dropdown">
                <meta name="Vision lang" content="ai ml"></meta>
                <Link className="nav-link text-white dropdown-toggle" id="navbarDropdown" data-bs-toggle="dropdown" 
                  role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  to={"/product-overview"}>Our Products<span className="sr-only"></span></Link>
                <div className="dropdown-menu">    
                  <Link className="dropdown-item" to={"/product-overview"}>Product Overview</Link>
                  <Link className="dropdown-item" to={"/keyword-extract"}>Text keyword extraction</Link>
                  <Link className="dropdown-item" to={"/object-detail"}>Object Detection</Link>
                  <Link className="dropdown-item" to={"/gender-detail"}>Gender and Age Detection</Link>
                  <Link className="dropdown-item" to={"/crowd-detail"}>Crowd Density Detection</Link>
                  <Link className="dropdown-item" to={"/optical-detail"}>Optical Character Recognition (OCR)</Link>
                  <Link className="dropdown-item" to={"/fashion-detail"}>Fashion Detection</Link>
                  <Link className="dropdown-item" to={"/lpr-detail"}>License Plate Recognition(LPR)</Link>
                </div>
              </li>
              <li className="nav-item active">
                <meta name="Vision lang Vision lang" content="ai ml"></meta>             
                <Link rel="preload"  className="nav-link text-white" to={"/about-us"}>About US</Link> 
              </li>

              <li className="nav-item active">
                        
                <Link rel="preload"  className="nav-link text-white" to={"/contact-us"}>Contact US</Link>
              </li>
              
            </ul>
            <ul className="navbar-nav  text-capitalize " >
              {!this.props.todoList.user.auth ? (
                <li className="nav-item">
                  <Link rel="preload"  className="nav-link text-white log-in" to={"/sign-in"}>login<span className="sr-only"></span></Link>
                </li>              ) : (
                  <li className="nav-item">
                    <Link rel="preload" onClick={this.logout} className="nav-link text-white log-in" to={"/sign-in"}>Logout<span className="sr-only"></span></Link>
                  </li> )}
              
              
              {/* <li className="nav-item">
              <Link rel="preload"  className="nav-link text-white log" to={"/sign-up"}>SignUp
              <span className="sr-only"></span></Link>
            </li> */}
              <li className="nav-item" style={{marginTop:"7px"}} >
                <Link  rel="preload"  className="nav-link text-white badge let-connect" to={"/"} >let&apos;s connect<span className="sr-only"></span></Link>
              </li>
            </ul>

          </div>
        </nav>
      </div>

    ); 
  } 
}
 

function mapStateToProps(state) {
  const { user  } = state
  return { todoList: {user} }
}

function mapDispatchToProps (dispatch) {
  return {
    rmuser:() => dispatch(removeUser()),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar)
