import {
  ADD_USER,
  REMOVE_USER,
} from "../actions"

const initialCalendarState = {
  name: null,
  auth: null,
  img:  null
}

const user = (state = initialCalendarState, action) =>{
  const { name, auth } = action
  
  switch (action.type) {
  case ADD_USER :
    return {
      ...state,
      name,
      auth,
    }
  case REMOVE_USER :
    return {
      ...state,
      name,
      auth
    }
  default :
    return state
  }
  
}

export default user;