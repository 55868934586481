import React, { Component } from "react"
import { connect } from "react-redux"
import "../../style/product-detail.scss"
// import log from "../../utils/assets/bg-log.svg";
import planetGreen from "../../utils/assets/planet-green.svg";
import planetPink from "../../utils/assets/planet-pink.svg";
import planetBlue from "../../utils/assets/planet-blue.svg";
import cldSml from "../../utils/assets/cloud-small.svg";
import cldMed from "../../utils/assets/cloud-med.svg";
import ufoO from "../../utils/assets/ufo-o.svg";
import astr from "../../utils/assets/astr.svg";
import psychiatry from "../../utils/assets/psychiatry.svg";
import dashboard from "../../utils/assets/dashboard.svg";
import asteroidRound from "../../utils/assets/asteroid-round.svg";
import cloudBtm from "../../utils/assets/cloud-btm.svg";
import planetRing from "../../utils/assets/planet-ring.svg";
import img1 from "../../utils/assets/img1.svg";
import img2 from "../../utils/assets/img2.svg";
import img3 from "../../utils/assets/img3.svg";
import planetGreen2 from "../../utils/assets/planet_green.svg";
import planetary from "../../utils/assets/planetary.svg";
import rocket2 from "../../utils/assets/rocket.svg";
import planetYellow2 from "../../utils/assets/planet-yellow2.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import planetBlu from "../../utils/assets/planet_blue.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import shootingStar from "../../utils/assets/shooting.png";
import computer from "../../utils/assets/computer-file-globe-Digital Marketing.svg";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ADD_NAVBAR } from "../../actions"


class KeywordExtraction extends Component {

  render() {
    // const { remove } = this.props
    return (
      <body id="product-detail"> 
        <img src={planetGreen} width="160" alt="Vision lang" className="planet-green img-fluid illustration-mob" />       
        <div className="h-90"></div>
        <div className="hero-section back-twinkle">
          <div className="container">
            <div className="content txt-center spacing">			
              <img src={planetPink} alt="Vision lang" width="400" className="planet-pink img-fluid illustration-mob" />
              <div className="row dis-f">
                <h1>Text Keyword extraction</h1>
                <div className="col-md-8 col-sm-12">
                  <h2>Extract the keywords that matter!</h2>
                  <p>Based on the advanced text analysis AI technique, our tool helps extract essential keywords, phrases, and expressions from large texts.</p>
                  <p>Billions of conversations happen in the online universe, and analyzing that data to find useful 
                    information about your brand can be overwhelming, and that&apos;s where our text keyword 
                    extraction AI tool will help you out.</p>
                  {/* <Link  to={{pathname:'/keyword-demo', aboutProps:{api:'object_detection_api', name:"Object Detection"}}}  className="run-btn">Run Demo</Link> */}
                  <Link  to={{pathname:"/text-demo/Keyword-extraction"}}  className="run-btn demo-btn">Run Demo</Link>
                </div>
              </div>	
            </div>
            <img src={cldMed} width="200" alt="Vision lang" className="cld-med img-fluid illustration-mob" />
            <img src={ufoO} width="300" alt="Vision lang" className="ufo img-fluid illustration-mob" />
          </div>
        </div>
        <div className="cases-section spacing">
          <div className="bg-star">
            <div className="container">
              <div className="content">
                <h2 className="txt-center">Use Cases and Applications</h2>
                <p><b>Social media monitoring</b></p>
                <p>Social media has an edge when it comes to extracting your audience&apos;s most in-depth data, knowing them better, 
                  and discovering the best ways to target them. Being a business owner, social media conversations offer excellent opportunities to understand your</p>
                <p>customer&apos;s unique requirements, where and who they are, how to attract them, analyze trends, enhance your services/products, 
                  and take prompt actions in case of a PR crisis. Our solution helps drive businesses to understand their customers or any data set.</p>

                <p>Leverage the power of our Keyword Extraction to keep an eye on people&apos;s opinions about your business, latest trends, monitor 
                  the activities of competitors, and conduct market research. Here are some more ways this tool can help you:</p>
                <div className="row cards">				
                  <img src={astr} width="100" alt="Vision lang" className="astr img-fluid illustration-mob" />		
                  <img src={planetBlue} width="400" alt="Vision lang" className="planet-blue img-fluid illustration-mob" />
                  <div className="col-lg-4">
                    <div className="bg-white">
                      <img src={psychiatry} width="125" alt="Vision lang" className="img-fluid" />
                      <h4>Psychographic Audience Analysis:</h4>
                      <p>Understand your customers according to their unique emotional aspects and key attributes such as preferences, desires, objectives, interests, and lifestyles.</p>
                    </div>
                  </div>
                  <div className="col-lg-4 mrgn">
                    <div className="bg-white">
                      <img src={dashboard} width="165" alt="Vision lang" className="img-fluid" />
                      <h4>Predictive Analytics Marketing:</h4>
                      <p>Our AI tool helps businesses craft their marketing strategies by predicting the
                        future actions of the audience. All this is done by using the insights gathered through several datasets and advanced programs.</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="bg-white">
                      <img src={computer} width="165" alt="Vision lang" className="img-fluid" />
                      <h4>Digital Reputation:</h4>
                      <p>Digital reputation is not just limited to social media. Instead, it is about every single mention
                        of your brand, reviews, comments, and opinions on a wide range of platforms, from blogs, forums to online business review sites.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quote txt-center spacing bg-twinkle">
          <img src={asteroidRound} alt="Vision lang" className="asteroid-round img-fluid illustration-mob" />	
          <div className="container">
            <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />	
            <div className="row dis-f">
              <div className="col-lg-7">
                <h3>Our Keyword Extraction AI technology will empower you to deal with a vast range of data and maintain your online reputation like never before!</h3>
              </div>
            </div>
          </div>
          <img src={cldMed} alt="Vision lang" className="cloud-med img-fluid illustration-mob" />
        </div>
        <div className="process-section spacing bg-twinkle">
          <div className="product bg-purple">
            <div className="spacing-p">
              <div className="bg-twinkle">							
                <img src={planetRing} alt="Vision lang" className="planet-ring img-fluid illustration-mob" />
                <div className="container">
                  <div className="internal-bus space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-right dis-f">
                        <img src={img1} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-left">
                        <h4>Automate internal business processes</h4>
                        <p>Our tool helps you automate various internal processes 
                          and save a considerable amount of time and efforts. 
                          You can automate summarizing documents, forms, 
                          reviews, etc., and extract useful information for making 
                          data-driven decisions.</p>
                      </div>
                    </div>
                  </div>
                  <div className="customer space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={img2} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Understand your customers</h4>
                        <p>You can gain an edge over your competitors if you 
                          understand your customers better. It will help you 
                          improve your products, services, and processes.
                        </p>
                        <p>Our keyword extraction tool will help you gain 
                          understandable bits from data sets and automate the 
                          tasks based on the extracted keywords.</p>
                      </div>
                    </div>
                  </div>
                  <div className="bus-intel space-margin">							
                    <img src={planetBlu} alt="Vision lang" className="planet_blue img-fluid illustration-mob" />
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={img3} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6">
                        <h4>Business Intelligence</h4>
                        <p>Market research and competitive business analysis are two main aspects in the space of Business Intelligence. Our AI tool will help you gather relevant information from diverse resources, enabling you to make data-driven decisions to take your business journey to the next level.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="industries txt-center bg-twinkle">
          <img src={shootingStar} alt="Vision lang" className="shoot img-fluid illustration-mob" />
          <img src={planetGreen2} alt="Vision lang" className="planet_green img-fluid illustration-mob" />
          <div className="bg-sky spacing">
            <div className="container">
              <h3>Industries that can enter the AI Space with our Keyword Extraction Technology</h3>
              <div className="row dis-f no-gutters">
                <div className="col-lg-5 col-md-7 col-10">
                  <a href="#">Politics</a>
                  <a href="#">Healthcare</a>
                  <a href="#">Information Technology (IT)</a>
                  <a href="#">eCommerce</a>
                  <a href="#">Online Business</a>
                </div>
              </div>
            </div>			
            <img src={planetary} alt="Vision lang" className="planetary img-fluid illustration-mob" />
          </div>
        </div>
        <div className="pricing txt-center spacing bg-twinkle">
          <div className="container">
            <img src={cldSml} alt="Vision lang" className="cloud-small img-fluid illustration-mob" />
            <img src={rocket2} alt="Vision lang" className="rocket img-fluid illustration-mob" />
            <h2>Pricing</h2>
            <div className="row dis-f">
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 0.00</span>
                    <h5>Basic</h5>
                    <p>10 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4 mrgn">
                <div className="dis-f txt-center">
                  <div className="price-content middle-price">
                    <span>$ 25.00</span>
                    <h5>Advanced</h5>
                    <p>200 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="middle-price txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 100.00</span>
                    <h5>Pro</h5>
                    <p>Unlimited</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
            </div>
          </div>		
          <img src={planetYellow2} alt="Vision lang" className="planet-yellow img-fluid illustration-mob" />
        </div>	
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">			
            <img src={cloudM} alt="Vision lang" className="cloud-m  img-fluid illustration-mob" />	
            <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
          </div>
          <div className="socialmedia dis-f">
            <a href="#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />
        </footer>
      </body>
    );
  }




}
// KeywordExtraction.propTypes = {
//   remove: PropTypes.fun,
// };

// function mapStateToProps(state, ownProps) {
//   // console.log(state) // state
//   // console.log(ownProps) // ownProps
// }

function mapDispatchToProps (dispatch) {
  return {
    remove: (data) => dispatch(ADD_NAVBAR(data)),
  }
}
export default connect(
  null,
  mapDispatchToProps
)(KeywordExtraction)
