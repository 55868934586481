import React, { Component } from "react"
import { connect } from "react-redux"
import "../../style/product-detail.scss"
import planetGreen from "../../utils/assets/planet-green.svg";
import planetPink from "../../utils/assets/planet-pink.svg";
import cldSml from "../../utils/assets/cloud-small.svg";
import cldMed from "../../utils/assets/cloud-med.svg";
import ufoO from "../../utils/assets/ufo-o.svg";
import cloudBtm from "../../utils/assets/cloud-btm.svg";
import planetRing from "../../utils/assets/planet-ring.svg";
import Risk from "../../utils/assets/risk.svg";
import Safety from "../../utils/assets/safety.svg";
import Monitoring from "../../utils/assets/monitoring.svg";
import planetGreen2 from "../../utils/assets/planet_green.svg";
import planetary from "../../utils/assets/planetary.svg";
import rocket2 from "../../utils/assets/rocket.svg";
import planetYellow2 from "../../utils/assets/planet-yellow.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import planetBlu from "../../utils/assets/planet_blue.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import shootingStar from "../../utils/assets/shooting.png";
import { Link } from "react-router-dom";
import { ADD_NAVBAR } from "../../actions"


class CrowdDetail extends Component {

  render() {
    // const { remove } = this.props
    return (
      <body id="product-detail">
        <div className="h-90"></div>
        <img src={planetGreen} width="160" alt="Vision lang" className="planet-green img-fluid illustration-mob" />
        <div className="hero-section crowd">
          <div className="bg-sky">
            <div className="container">
              <div className="content txt-center spacing">			
                <img src={planetPink} alt="Vision lang" width="400" className="planet-pink img-fluid illustration-mob" />
                <div className="row dis-f">
                  <h1>Crowd Density Detection</h1>
                  <div className="col-md-8 col-sm-12">
                    <h2>Estimate crowd size and enhance public safety</h2>
                    <p>Vision lang takes pride in presenting AI-powered Crowd Detection Technology that enables businesses and organizations to automatically detect crowd density. All you need to upload is an image of the crowd in an area, and our system will provide size estimation.</p>
                    {/* <Link  to={{pathname:'/keyword-demo', aboutProps:{api:'object_detection_api', name:"Object Detection"}}}  className="run-btn">Run Demo</Link> */}
                    <Link to={{pathname:"/optocal-demo/Crowd-detection"}}  target="_blank" className="run-btn demo-btn">Run Demo</Link>
                  </div>
                </div>	
              </div>
              <img src={cldMed} width="200" alt="Vision lang" className="cld-med img-fluid illustration-mob" />
              <img src={ufoO} width="300" alt="Vision lang" className="ufo img-fluid illustration-mob" />
            </div>
          </div>
        </div>
        <div className="process-section spacing bg-twinkle">
          <div className="product bg-purple">
            <div className="spacing-p">
              <div className="bg-twinkle">                        				
                <img src={planetBlu} alt="Vision lang" className="planet-ring img-fluid illustration-mob" />
                <div className="container">
                  <div className="case-title">
                    <h2 className="txt-center">Use Cases and Applications</h2>
                  </div>
                  <div className="internal-bus space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-right dis-f">
                        <img src={Risk} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-left">
                        <h4>Crowd size estimation for events and managing risks</h4>
                        <p>Our Crowd Detection tool helps estimate the crowd size in public 
                          events. When we talk about crowded places, there are various risks
                          that can happen over there. By knowing the size of the crowd, 
                          you can tackle the risks efficiently.</p>
                      </div>
                    </div>
                  </div>
                  <div className="customer space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={Safety} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Safety of public</h4>
                        <p>Whether it is a music concert or a political campaign, if anticipated 
                          crowd size exceeds, things can go wrong. Our system can help you make
                          appropriate decisions regarding the security and safety of the public.</p>
                      </div>
                    </div>
                  </div>
                  <div className="bus-intel space-margin">							
                    <img src={planetRing} alt="Vision lang" className="planet_blue img-fluid illustration-mob" />	
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={Monitoring} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6">
                        <h4>Crowd capacity level compliance monitoring</h4>
                        <p>Are you an event organizer and have to keep an eye on the crowd capacity 
                          level compliance at the venue? When it comes to larger events, 
                          it is a challenging task to keep up with the numbers, and our smart
                          Crowd Detection system has been designed just to do that. </p>
                        <p>It can also be used as an advanced Covid19 distance tracking and 
                          reporting tool for better crowd management during these times of global pandemic.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="industries txt-center bg-twinkle">
          <div className="bg-sky spacing">
            <img src={shootingStar} alt="Vision lang" className="shoot img-fluid illustration-mob" />
            <img src={planetGreen2} alt="Vision lang" className="planet_green img-fluid illustration-mob" />
            <div className="container">
              <h3>Industries that can benefit from our Crowd Density Detection Technology</h3>
              <div className="row dis-f no-gutters">
                <div className="col-lg-5 col-md-7 col-10">
                  <a href="#">Crowd management</a>
                  <a href="#">Covid19 distance tracking and reporting</a>
                  <a href="#">Politics Rallies</a>
                  <a href="#">Flocks</a>
                  <a href="#">Military purpose</a>
                  <a href="#">Sports</a>
                  <a href="#">Transport</a>
                  <a href="#">Event planning and management</a>
                  <a href="#">Restaurant and night-life areas</a>
                  <a href="#">Security (public places)</a>
                </div>
              </div>
            </div>			
            <img src={planetary} alt="Vision lang" className="planetary img-fluid illustration-mob" />
          </div>
        </div>
        <div className="pricing txt-center spacing bg-twinkle">
          <div className="container">
            <img src={cldSml} alt="Vision lang" className="cloud-small img-fluid illustration-mob" />
            <img src={rocket2} alt="Vision lang" className="rocket img-fluid illustration-mob" />
            <h2>Pricing</h2>
            <div className="row dis-f">
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 0.00</span>
                    <h5>Basic</h5>
                    <p>10 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4 mrgn">
                <div className="dis-f txt-center">
                  <div className="price-content middle-price">
                    <span>$ 25.00</span>
                    <h5>Advanced</h5>
                    <p>200 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="middle-price txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 100.00</span>
                    <h5>Pro</h5>
                    <p>Unlimited</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
            </div>
          </div>		
          <img src={planetYellow2} alt="Vision lang" className="planet-yellow img-fluid illustration-mob" />
        </div>	
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">			
            <img src={cloudM} alt="Vision lang" className="cloud-m  img-fluid illustration-mob" />	
            <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
          </div>
          <div className="socialmedia dis-f">
            <a href="#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />
        </footer>
      </body>
    );
  }




}
// KeywordExtraction.propTypes = {
//   remove: PropTypes.fun,
// };

// function mapStateToProps(state, ownProps) {
//   // console.log(state) // state
//   // console.log(ownProps) // ownProps
// }

function mapDispatchToProps (dispatch) {
  return {
    remove: (data) => dispatch(ADD_NAVBAR(data)),
  }
}
export default connect(
  null,
  mapDispatchToProps
)(CrowdDetail)
