import React, { Component } from "react"
import { connect } from "react-redux"
import "../../style/product-detail.scss"
// import log from "../../utils/assets/bg-log.svg";
import planetGreen from "../../utils/assets/planet-green.svg";
import planetPink from "../../utils/assets/planet-pink.svg";
// import planetBlue from "../../utils/assets/planet-blue.svg";
import cldSml from "../../utils/assets/cloud-small.svg";
import cldMed from "../../utils/assets/cloud-med.svg";
import ufoO from "../../utils/assets/ufo-o.svg";
// import astr from "../../utils/assets/astr.svg";
// import psychiatry from "../../utils/assets/psychiatry.svg";
// import dashboard from "../../utils/assets/dashboard.svg";
// import asteroidRound from "../../utils/assets/asteroid-round.svg";
import cloudBtm from "../../utils/assets/cloud-btm.svg";
import planetRing from "../../utils/assets/planet-ring.svg";
import Bank from "../../utils/assets/bank.svg";
import Legal from "../../utils/assets/legal.svg";
import Internal from "../../utils/assets/internal.svg";
import Data from "../../utils/assets/data.svg";
import Backup from "../../utils/assets/backup.svg";
import planetGreen2 from "../../utils/assets/planet_green.svg";
import MeteorShoot from "../../utils/assets/meteor-shoot.svg";
import planetary from "../../utils/assets/planetary.svg";
import rocket2 from "../../utils/assets/rocket.svg";
import planetYellow2 from "../../utils/assets/planet-yellow.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import planetBlu from "../../utils/assets/planet_blue.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import shootingStar from "../../utils/assets/shooting.png";
// import computer from "../../utils/assets/computer-file-globe-Digital Marketing.svg";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ADD_NAVBAR } from "../../actions"


class OpticalDetail extends Component {

  render() {
    // const { remove } = this.props
    return (
      <body id="product-detail" className="optical">        
        <div className="h-90"></div>
        <img src={planetGreen} width="160" alt="Vision lang" className="planet-green img-fluid illustration-mob" />
        <div className="hero-section crowd optical">
          <div className="bg-sky">
            <div className="container">
              <div className="content txt-center spacing">				
                <img src={planetPink} alt="Vision lang" width="400" className="planet-pink img-fluid illustration-mob" />
                <div className="row dis-f">
                  <h1>Optical Character Recognition</h1>
                  <div className="col-md-8 col-sm-12">
                    <h2>Automatically extract text from images in seconds</h2>
                    <p>Our Optical character recognition (OCR) model will empower you to extract accurate text and data from 
                      the scanned images quickly. Our experts have trained this model to be effective in a wide range of areas, 
                      from scanning documents, receipts, invoices, bank statements, and visiting cards to handwritten 
                      documents. Our model ensures maximum result accuracy and data security. Minimize your data 
                      processing efforts and reduce manual data entry expenses with the OCR tool by Vision lang. </p>
                    {/* <Link  to={{pathname:'/keyword-demo', aboutProps:{api:'object_detection_api', name:"Object Detection"}}}  className="run-btn">Run Demo</Link> */}
                    <Link  to={{pathname:"/optocal-demo/OCR"}}  className="run-btn demo-btn">Run Demo</Link>
                  </div>
                </div>
              </div>
              <img src={cldMed} width="200" alt="Vision lang" className="cld-med img-fluid illustration-mob" />
              <img src={ufoO} width="300" alt="Vision lang" className="ufo img-fluid illustration-mob" />
            </div>
          </div>
        </div>
        <div className="process-section spacing bg-twinkle">
          <div className="product bg-purple">
            <div className="spacing-p">
              <div className="bg-twinkle">                        				
                <img src={planetBlu} alt="Vision lang" className="planet-ring img-fluid illustration-mob" />	
                <img src={MeteorShoot} alt="Vision lang" className="meteor-shoot img-fluid illustration-mob" />	
                <div className="container">
                  <div className="case-title">
                    <h2 className="txt-center">Use Cases and Applications</h2>
                  </div>
                  <div className="internal-bus space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-right dis-f">
                        <img src={Bank} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-left">
                        <h4>Banking</h4>
                        <p>The banking industry is one of the biggest consumers of OCR 
                          y as they have to process a wide range of data in the form of 
                          receipts, handwritten cheques, and documents. These documents 
                          are scanned and transformed into digital text in real-time for
                          effective handling. It helps with minimized turnaround time 
                          when it comes to cheque clearance.</p>
                      </div>
                    </div>
                  </div>
                  <div className="customer space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={Legal} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Legal</h4>
                        <p>There is enormous paperwork in the legal industry, from
                          affidavits, judgments, filings, statements to other 
                          documents. Using OCR technology, it is quick and easy
                          to process all this information.</p>
                      </div>
                    </div>
                  </div>
                  <div className="bus-intel space-margin">							
                    <img src={planetRing} alt="Vision lang" className="planet_blue img-fluid illustration-mob" />	
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={Internal} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6">
                        <h4>Automate internal processes</h4>
                        <p>As already discussed, manual data entry tasks can be 
                          automated effectively using the OCR system. It will 
                          help save a significant amount of time, resources, and 
                          expenses. </p>
                      </div>
                    </div>
                  </div>
                  <div className="security space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={Data} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Access data anywhere and anytime </h4>
                        <p>Once an image is scanned by OCR, all the data can be
                          uploaded to a shared database, enabling anyone to
                          access this data anywhere and anytime. The primary 
                          application is in the government works, such as finding
                          your property records or other documents like birth
                          certificates from anywhere.</p>
                      </div>
                    </div>
                  </div>
                  <div className="cctv space-margin">						
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={Backup} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6">
                        <h4>Data backup</h4>
                        <p>Data backup in the digital form using OCR is an 
                          affordable method compared to saving data in the 
                          paper forms.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="industries txt-center bg-twinkle">
          <div className="bg-sky spacing">
            <img src={shootingStar} alt="Vision lang" className="shoot img-fluid illustration-mob" />
            <img src={planetGreen2} alt="Vision lang" className="planet_green img-fluid illustration-mob" />
            <div className="container">
              <h3>Industries that can benefit from our Object Detection Technology</h3>
              <div className="row dis-f no-gutters">
                <div className="col-lg-5 col-md-7 col-10">
                  <a href="#">Crowd management</a>
                  <a href="#">Covid19 distance tracking and reporting</a>
                  <a href="#">Politics Rallies</a>
                  <a href="#">Flocks</a>
                  <a href="#">Military purpose</a>
                  <a href="#">Sports</a>
                  <a href="#">Transport</a>
                  <a href="#">Event planning and management</a>
                  <a href="#">Security (public places)</a>
                </div>
              </div>
            </div>			
            <img src={planetary} alt="Vision lang" className="planetary img-fluid illustration-mob" />
          </div>
        </div>
        <div className="pricing txt-center spacing bg-twinkle">
          <div className="container">
            <img src={cldSml} alt="Vision lang" className="cloud-small img-fluid illustration-mob" />
            <img src={rocket2} alt="Vision lang" className="rocket img-fluid illustration-mob" />
            <h2>Pricing</h2>
            <div className="row dis-f">
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 0.00</span>
                    <h5>Basic</h5>
                    <p>10 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4 mrgn">
                <div className="dis-f txt-center">
                  <div className="price-content middle-price">
                    <span>$ 25.00</span>
                    <h5>Advanced</h5>
                    <p>200 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="middle-price txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 100.00</span>
                    <h5>Pro</h5>
                    <p>Unlimited</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
            </div>
          </div>		
          <img src={planetYellow2} alt="Vision lang" className="planet-yellow img-fluid illustration-mob" />
        </div>	
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">			
            <img src={cloudM} alt="Vision lang" className="cloud-m  img-fluid illustration-mob" />	
            <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
          </div>
          <div className="socialmedia dis-f">
            <a href="#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />
        </footer>
      </body>
    );
  }




}
// KeywordExtraction.propTypes = {
//   remove: PropTypes.fun,
// };

// function mapStateToProps(state, ownProps) {
//   // console.log(state) // state
//   // console.log(ownProps) // ownProps
// }

function mapDispatchToProps (dispatch) {
  return {
    remove: (data) => dispatch(ADD_NAVBAR(data)),
  }
}
export default connect(
  null,
  mapDispatchToProps
)(OpticalDetail)
