import React, { Component } from "react"
import { connect } from "react-redux"
import "../../style/product-detail.scss"
// import log from "../../utils/assets/bg-log.svg";
import planetGreen from "../../utils/assets/planet-green.svg";
import planetPink from "../../utils/assets/planet-pink.svg";
import cldSml from "../../utils/assets/cloud-small.svg";
import cldMed from "../../utils/assets/cloud-med.svg";
import ufoO from "../../utils/assets/ufo-o.svg";

import cloudBtm from "../../utils/assets/cloud-btm.svg";
import planetRing from "../../utils/assets/planet-ring.svg";
import Visitor from "../../utils/assets/visitor.svg";
import Camp from "../../utils/assets/camp.svg";
import Tailor from "../../utils/assets/tailor.svg";
import planetGreen2 from "../../utils/assets/planet_green.svg";
import MeteorShoot from "../../utils/assets/meteor-shoot.svg";
import planetary from "../../utils/assets/planetary.svg";
import rocket2 from "../../utils/assets/rocket.svg";
import planetYellow2 from "../../utils/assets/planet-yellow.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import planetBlu from "../../utils/assets/planet_blue.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import shootingStar from "../../utils/assets/shooting.png";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ADD_NAVBAR } from "../../actions"


class GenderDetail extends Component {

  render() {
    // const { remove } = this.props
    return (
      <body id="product-detail">        
        <div className="h-90"></div>
        <img src={planetGreen} width="160" alt="Vision lang" className="planet-green img-fluid illustration-mob" />
        <div className="hero-section crowd gender">
          <div className="bg-sky">
            <div className="container">
              <div className="content txt-center spacing">
                <img src={planetPink} alt="Vision lang" width="400" className="planet-pink img-fluid illustration-mob" />
                <div className="row dis-f">
                  <h1>Gender and Age Detection</h1>
                  <div className="col-md-8 col-sm-12">
                    <h2>Know your customers like never before.</h2>
                    <p>Finding out Who are our target customer&apos;s is the biggest challenge
                      faced by the marketing astronauts. Here are some features of this
                      tool that will help you deal with this question:</p>
                    <ul>
                      <li>Analyze your CCTV recordings, images, and get live feedback regarding who
                        is coming into your store, who your customers are, etc.</li>
                      <li>Connect with multiple solutions, like WIFI sign-in solution,
                        to better understand more of your customers</li>
                      <li>Connect to your POS, to know when customers entered and left the store and who they are</li>
                      <li>Our Gender and Age detection tool empowers brands to discover their customer
                        demographics in the most accurate manner.</li>
                    </ul>
                    {/* <Link  to={{pathname:'/keyword-demo', aboutProps:{api:'object_detection_api', name:"Object Detection"}}}  className="run-btn">Run Demo</Link> */}
                    <Link  to={{pathname:"/demo/Gender-age-detection"}}  className="run-btn demo-btn">Run Demo</Link>
                  </div>
                </div>				
              </div>
              <img src={cldMed} width="200" alt="Vision lang" className="cld-med img-fluid illustration-mob" />
              <img src={ufoO} width="300" alt="Vision lang" className="ufo img-fluid illustration-mob" />
            </div>
          </div>
        </div>
        <div className="process-section spacing bg-twinkle">
          <div className="product bg-purple">
            <div className="spacing-p">
              <div className="bg-twinkle">                        				
                <img src={planetBlu} alt="Vision lang" className="planet-ring img-fluid illustration-mob" />
                <img src={MeteorShoot} alt="Vision lang" className="meteor-shoot img-fluid illustration-mob" />
                <div className="container">
                  <div className="case-title">
                    <h2 className="txt-center">Use Cases and Applications</h2>
                  </div>
                  <div className="internal-bus space-margin">						
                    <div className="row dis-f">
                      <div className="col-md-6 p-right dis-f">
                        <img src={Visitor} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-left">
                        <h4>Discover who are your visitors</h4>
                        <p>It will help you discover specific gender and age groups who are
                          visiting and walking into your store. </p>
                      </div>
                    </div>
                  </div>
                  <div className="customer space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={Camp} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Find out your potential customers and create 
                          appropriate marketing campaigns for them</h4>
                        <p>Our Gender and Age detection tool will help you find 
                          who your visitors are. It will help you grab the 
                          business opportunities by finding out the right 
                          demographics of visitors. Use this information to 
                          create your marketing campaigns and turn them into 
                          your customers.</p>
                      </div>
                    </div>
                  </div>
                  <div className="bus-intel space-margin">							
                    <img src={planetRing} alt="Vision lang" className="planet_blue img-fluid illustration-mob" />	
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={Tailor} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6">
                        <h4>Tailor-made customer experience</h4>
                        <p>Any business can attract more sales by providing users with a tailor-made 
                          customer experience, and it is possible when you know them enough. Use our
                          tool to explore the demographics and impress them with the unique customer 
                          experience</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="industries object-detail txt-center bg-twinkle">
          <div className="bg-sky spacing">
            <img src={shootingStar} alt="Vision lang" className="shoot img-fluid illustration-mob" />
            <img src={planetGreen2} alt="Vision lang" className="planet_green img-fluid illustration-mob" />
            <div className="container">
              <h3>Industries that can benefit from our Gender and Age Detection Technology</h3>
              <div className="row dis-f no-gutters">
                <div className="col-lg-5 col-md-7 col-10">
                  <a href="#">Politics</a>
                  <a href="#">Retail</a>
                  <a href="#">Healthcare</a>
                  <a href="#">Information Technology (IT)</a>
                  <a href="#">eCommerce</a>
                  <a href="#">Object Businesses</a>
                </div>
              </div>
            </div>			
            <img src={planetary} alt="Vision lang" className="planetary img-fluid illustration-mob" />
          </div>
        </div>
        <div className="pricing txt-center spacing bg-twinkle">
          <div className="container">
            <img src={cldSml} alt="Vision lang" className="cloud-small img-fluid illustration-mob" />
            <img src={rocket2} alt="Vision lang" className="rocket img-fluid illustration-mob" />
            <h2>Pricing</h2>
            <div className="row dis-f">
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 0.00</span>
                    <h5>Basic</h5>
                    <p>10 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4 mrgn">
                <div className="dis-f txt-center">
                  <div className="price-content middle-price">
                    <span>$ 25.00</span>
                    <h5>Advanced</h5>
                    <p>200 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="middle-price txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 100.00</span>
                    <h5>Pro</h5>
                    <p>Unlimited</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
            </div>
          </div>		
          <img src={planetYellow2} alt="Vision lang" className="planet-yellow img-fluid illustration-mob" />
        </div>	
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">			
            <img src={cloudM} alt="Vision lang" className="cloud-m img-fluid illustration-mob" />	
            <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
          </div>
          <div className="socialmedia dis-f">
            <a href="#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />
        </footer>
      </body>
    );
  }




}
// KeywordExtraction.propTypes = {
//   remove: PropTypes.fun,
// };

// function mapStateToProps(state, ownProps) {
//   // console.log(state) // state
//   // console.log(ownProps) // ownProps
// }

function mapDispatchToProps (dispatch) {
  return {
    remove: (data) => dispatch(ADD_NAVBAR(data)),
  }
}
export default connect(
  null,
  mapDispatchToProps
)(GenderDetail)
