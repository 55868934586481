/* eslint-disable no-undef */
import React, { Component } from "react";
import { connect } from "react-redux"
import "../../style/keyword-demo.scss"
// import log from "../../utils/assets/bg-log.svg";
// import bgTwinkle from "../../utils/assets/bg-twinkle.png";
import planetYellow from "../../utils/assets/planet-yellow.svg";
import imageGallery from "../../utils/assets/image-gallery.svg";
// import gallery from "../../utils/assets/gallery.svg";
import systemUpload from "../../utils/assets/system-upload.svg";
import execute from "../../utils/assets/execute.svg";
import download from "../../utils/assets/download.svg";
import line from "../../utils/assets/line.svg";
import planetG from "../../utils/assets/planet-g.svg";
import rocketBtm from "../../utils/assets/rocket-btm.svg";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import cloudBtm from "../../utils/assets/cloud-btm.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import cldSml from "../../utils/assets/cloud-small.svg";
// import demo_out1 from "../../utils/demo_out.jpg";
// import demo_in1 from "../../utils/demo_in.jpg";
import { Link } from "react-router-dom";
import rocket from "../../utils/rocket.png";
import planetPink from "../../utils/assets/planet-pink.svg";
import * as ContactsAPI from "../../Contact-api"
import PropTypes from "prop-types";
// import in_1 from "../../utils/1.jpg";
import out_1 from "../../utils/1_out.jpg";
// import in_2 from "../../utils/3.jpg";
import out_4 from "../../utils/4_out.jpg";
// import in_3 from "../../utils/4.jpg";
import out_3 from "../../utils/3_out.jpg";
// import banana from "../../utils/banan.jpg";
import out_2 from "../../utils/2_out.jpg";
// import in_4 from "../../utils/6.jpg";
// import in_5 from "../../utils/13.jpg";

// import { useLocation } from "react-router-dom";



class IMGDemo extends Component {
  constructor(props){
    super(props)
    this.state = {
      url: imageGallery,
      bool: false,
      out_url:imageGallery,
      auth:true,
      formData:null,
      carousel:true,
      count:0


    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.demo = this.demo.bind(this)
    
  }

  componentDidMount(){
    console.log(this.props.todoList)
    if(this.props.todoList.user.auth!==null){
      this.setState({
        auth:false
      })
    }
  }


  static get propTypes() { 
    return { 
      todoList:PropTypes.any,
      match: PropTypes.any, 
      aboutProps: PropTypes.func 
    }; 
  }
  incrementCount= () => {
    this.setState({
      carousel:false,
      count:this.state.count+1
    })
    
  }
  
  decrementCount= () => {
    this.setState({
      carousel:false,
      count:this.state.count-1
    })
  }

  handleChange(e) {
    this.setState({
      carousel:true
    })
    let file = e.target.files[0];

    let formData = new FormData();
    this.setState({
      url:URL.createObjectURL(file),
      bool: false,
    })  
    formData.append("file", file);
    this.setState({
      formData
    }) 
    
  }

  handleSubmit() {
    this.demo(0)

    if(this.state.carousel){
      this.setState({
        bool:true
      })
      let api = null

      if(this.props.match.params.name==="Object-detection"){
        api = "object_detection_api"
      }
      if(this.props.match.params.name==="Fashion-detection"){
        api = "fashion_detection_api"
      }
    
      if(this.props.match.params.name==="Gender-age-detection"){
        api = "gender"
      }

      ContactsAPI.UploadFilesService(api,this.state.formData).then((res) => {
        this.setState({
          out_url:res.data.path,
          bool:false

        })  
      }).catch((error) => {
        this.setState({
          bool:false
        })  
        console.log(error)
      }
      )
    }else{
      
      this.setState({
        bool:true
      })
      setTimeout(
        () => {this.setState({ bool: false});     this.demo(this.state.count)
        }, 
        3000
      );
    }
  }

  demo(id) {
    // eslint-disable-next-line default-case
    switch (id) {
    case 0:
      this.setState({
        out_url:imageGallery,
    
      }) 
      break;

    case 1:
      this.setState({
        out_url:out_1,
  
      }) 
      break;
    case 2:
      this.setState({
        out_url:out_2
      }) 
      break;
    case 3:
      this.setState({
        out_url:out_3
      }) 
      break;
    case 4:
      this.setState({
        out_url:out_4
      }) 
  
    }

  }
  render() {
    let {out_url, bool, url,count} = this.state;
    let {name} = this.props.match.params
    console.log(out_url,bool,count)

    return (
      <div id="object-demo">
        <div>
          { (bool)  && 
          <div className="modal-content gif_default"> 
            <img  className="gif col-md-5 " src={rocket}   alt="loading..." />
          </div>
          }
        </div>
        <div className="h-90"></div>
        <div className="hero-section bg-twinkle">
          <img src={cldSml} alt="Vision lang" className="cld-small img-fluid illustration-mob" />
          <div className="container">
            <div className="content txt-center spacing">
              <div className="row dis-f">
                <div className="col-md-8">
                  {name==="Object-detection" && <h1>Object Detection</h1>}
                  {name==="Gender-age-detection" && <h1>Gender And Age Detection</h1>}
                  {name==="Fashion-detection" && <h1>Fashion Detection</h1>}
                  <h2>Detect and analyze objects from images and videos</h2>
                </div>
              </div>				
              <img src={planetYellow} alt="Vision lang" className="planet-yellow img-fluid illustration-mob" />
            </div>
          </div>
        </div>
        <div className="upload-section">
          <div className="bg-twinkle">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 images-padding">
                  <div className="img-gallery d-none">
                    <img id="blah" src={url} alt="Vision lang" />
                  </div>
                  {!this.props.todoList.user.auth ? (
                    <button type="button" className="btn btn-primary pop-load" data-toggle="modal" data-target="#exampleModal">
                      Upload Image
                    </button>
                  ) : (
                    <input type='file' onChange={this.handleChange} className="upload" />
                  )}
                  
                  <div className="img-gallery car">
                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel" data-interval="false">
                      <div className="carousel-inner">
                        {name!=="Object-detection" &&

                        <div className="carousel-item active">
                          <img className="d-block" src={url} alt="First slide"/>
                          <div className="carousel-caption d-none d-md-block">
                            <h5 className="text-primary">Upload file or click `&gt;` to scroll left</h5>
                          </div>
                        </div>
                        }
                        {name==="Object-detection" &&
                        <div>
                          {this.state.carousel &&
                          <div className="carousel-item active">
                            <img className="d-block" src={url} alt="first"/>
                            <div className="carousel-caption d-none d-md-block">
                              <h5 className="text-primary">Upload file or click `&gt;` to scroll left</h5>
                            </div>
                          </div>
                          }
                          {!this.state.carousel &&

                          <div className="carousel-item active ">
                            {/* <img className="d-block" src={require(`../../utils/1.jpg`)} alt="First slide"  /> */}
                            <img className="d-block" src={require(`../../utils/${count}.jpg`).default} alt={`${count}`}  />
                            {/* onClick={() => this.demo(0)} */}
                          </div>
                          }
                          
                        </div>
                        }
                      </div>
                    </div>
                    {name=="Object-detection" && 
                    <div>
                      {count>0 && 

                      <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon"  onClick={this.decrementCount}  aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      }
                      {count<4 &&

                      <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon"  onClick={this.incrementCount} aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                      </a>
                      }
                    </div>
                    
                    }
                  </div>


                </div>
                <div className="col-lg-6 images-padding download-section">
                  <a href={out_url}>Download</a>
                  <div className="img-gallery">
                    <img src={out_url} alt="Vision lang" />
                  </div>
                </div>
              </div>
              <div className="button-ex">
                <button onClick={this.handleSubmit} className="run-btn">Execute</button>
              </div>
            </div>
          </div>
        </div>
        <div className="keyword-step bg-twinkle spacing txt-center">		
          <img src={planetG} alt="Vision lang" className="planet-g img-fluid illustration-mob" />	
          <img src={planetPink} alt="Vision lang" className="planet-pink img-fluid illustration-mob" />
          <div className="container">
            <h2 className="spacing">How to Extract Keyword</h2>
            <div className="step-execute">
              <div className="step">
                <a><img src={systemUpload} alt="Vision lang" /></a>
                <div className="step-detail">
                  <h4>Step1</h4>
                  <p>Choose a file from your sytem and upload it</p>
                </div>
              </div>
              <div className="line">
                <img src={line} alt="Vision lang" />
              </div>
              <div className="step side-line">
                <a ><img src={execute} alt="Vision lang" /></a>
                <div className="step-detail">
                  <h4>Step2</h4>
                  <p>Now click on execute button</p>
                </div>
              </div>
              <div className="line">
                <img src={line} alt="Vision lang" />
              </div>
              <div className="step">
                <a ><img src={download} alt="Vision lang" /></a>
                <div className="step-detail">
                  <h4>Step3</h4>
                  <p>You can see your result on screen and download it</p>
                </div>
              </div>
            </div>	
            <div className="button-ex">
              <a href="#" className="run-btn object">Watch a video</a>
              <a href="#" className="run-btn object book mrgn-btn">Book a consultation</a>
            </div>	
            <img src={rocketBtm} alt="Vision lang" className="rocket-btm  img-fluid illustration-mob" />
            <img src={cloudM} alt="Vision lang" className="cloud-m  img-fluid illustration-mob" />	
          </div>		
          <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
        </div>
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">
          </div>
          <div className="socialmedia dis-f">
            <a href="#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />
        </footer>
        <div className="modal fade popup-log" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="pop-modal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <h4>In order to use our Service please Login</h4>
                  <Link to={{pathname:"/sign-in"}}  target="_blank" className="run-btn">Log In</Link>                    
                </div>
                <div className="modal-footer">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




    );
  }
}
/*<script>
}</script>*/

function mapStateToProps(state) {
  const { user  } = state
  return { todoList: {user} }
}


export default connect(
  mapStateToProps
)(IMGDemo)