import React, { Component } from "react"
import { connect } from "react-redux"
import "../../style/product-detail.scss"
import "../../style/about.scss"
// import log from "../../utils/assets/bg-log.svg";
import planetGreen from "../../utils/assets/planet-green.svg";
import planetBlue from "../../utils/assets/planet-blue.svg";
// import cldSml from "../../utils/assets/cloud-small.svg";
import cldMed from "../../utils/assets/cloud-med.svg";
import ufoO from "../../utils/assets/ufo-o.svg";

import cloudBtm from "../../utils/assets/cloud-btm.svg";

import planetGreen2 from "../../utils/assets/planet_green.svg";

import planetYellow2 from "../../utils/assets/planet-yellow.svg";
import astronaut from "../../utils/assets/astronaut.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import shootingStar from "../../utils/assets/shooting.png";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ADD_NAVBAR } from "../../actions"


class AboutUs extends Component {

  render() {
    // const { remove } = this.props
    return (
      <div id="product-detail" className="about">
        <div className="h-90"></div>
        <div className="hero-section crowd">
          <div className="bg-sky">
            <div className="container">
              <div className="content txt-center">
                <div className="row dis-f">
                  <img src={planetBlue} alt="Vision lang" width="400"  className="opacity-25 cld-small  img-fluid illustration-mob" />
                  <div className="col-md-8 col-sm-12 col-10"> 
                    <h1>About Us</h1>
                  </div>
                </div>				
                <img src={planetBlue} alt="Vision lang" width="400" className="planet-blue img-fluid illustration-mob" />
              </div>
              <img src={cldMed} width="200" alt="Vision lang" className="cld-med img-fluid illustration-mob" />
            </div>
          </div>
        </div>
        <div className="about-us bg-sky">
          <div className="bg-twinkle">
            <div className="container">
              <div className="row">
                <div className="col-md-6 .bg-white">
                  <h2>Vision lang- Your partner to revolutionize 
                    business processes through AI</h2>
                  <p>We are a team of AI experts with years of experience in crafting state of the
                    art business solutions. We aim at empowering businesses to automate their 
                    processes to save time and resources on repetitive tasks.</p>
                  <p>We are the data scientists and enthusiastic programmers, combining psychological 
                    facts, behavioral analysis, accurate computer vision, and business strategies to create
                    end-to-end solutions for modern businesses.</p>
                  <p>We have successfully created revolutionizing AI solutions like Text keyword 
                    extraction, Gender and Age Detection, Object Detection, Crowd Density Detection,
                    and many more. The experts train our machine learning solutions to provide 
                    maximum accuracy that helps scale up your business processes.</p>
                  <p>The shared enthusiasm of the Artificial intelligence team at Vision lang drives
                    us to create next-generation enterprise-grade solutions for a better future.</p>
                </div>
                <div className="col-md-6">                        
                  <img src={planetGreen} width="160" alt="Vision lang" className="planet-green img-fluid illustration-mob opacity-25" />
                  <img src={astronaut} alt="Vision lang" className="astronaut img-fluid illustration-mob" /> 
                </div>
              </div>	
              <img src={ufoO} width="150" alt="Vision lang" className="ufo img-fluid illustration-mob" />
            </div>
          </div>		
        </div>
        <img src={planetYellow2} alt="Vision lang" className="planet-yellow img-fluid illustration-mob" /> 
        <div className="connect txt-center bg-twinkle">                         
          <img src={shootingStar} width="200" alt="Vision lang" className="shooting img-fluid illustration-mob" />              
          <img src={planetGreen2} width="200" alt="Vision lang" className="planet_green img-fluid illustration-mob" />
          <div className="container">
            <h2>Contact us for more</h2>            
            <Link  rel="preload"  className="run-btn demo-btn" to={"/"} >let&apos;s connect</Link>
          </div>
        </div>		
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">			
            <img src={cloudM} alt="Vision lang" className="cloud-m  img-fluid illustration-mob" />	
            <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
          </div>
          <div className="socialmedia dis-f">
            <a href="#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" />
        </footer>
      </div>
    );
  }




}
// KeywordExtraction.propTypes = {
//   remove: PropTypes.fun,
// };

// function mapStateToProps(state, ownProps) {
//   // console.log(state) // state
//   // console.log(ownProps) // ownProps
// }

function mapDispatchToProps (dispatch) {
  return {
    remove: (data) => dispatch(ADD_NAVBAR(data)),
  }
}
export default connect(
  null,
  mapDispatchToProps
)(AboutUs)
