import React, { Component } from "react"
import { connect } from "react-redux"
import "../../style/product-detail.scss"
// import log from "../../utils/assets/bg-log.svg";
import planetGreen from "../../utils/assets/planet-green.svg";
import planetPink from "../../utils/assets/planet-pink.svg";
// import planetBlue from "../../utils/assets/planet-blue.svg";
import cldSml from "../../utils/assets/cloud-small.svg";
import cldMed from "../../utils/assets/cloud-med.svg";
import ufoO from "../../utils/assets/ufo-o.svg";
// import astr from "../../utils/assets/astr.svg";
// import psychiatry from "../../utils/assets/psychiatry.svg";
// import dashboard from "../../utils/assets/dashboard.svg";
// import asteroidRound from "../../utils/assets/asteroid-round.svg";
import cloudBtm from "../../utils/assets/cloud-btm.svg";
import planetRing from "../../utils/assets/planet-ring.svg";
import Visual from "../../utils/assets/visual.svg";
import Deep from "../../utils/assets/deep.svg";
import EnhanceUser from "../../utils/assets/enhance-user.svg";
import Online from "../../utils/assets/online.svg";
import Personal from "../../utils/assets/personal.svg";
import Reduce from "../../utils/assets/reduce.svg";
import Profile from "../../utils/assets/profile.svg";
import Recommend from "../../utils/assets/recommend.svg";
import celeb from "../../utils/assets/celeb.svg";
import planetGreen2 from "../../utils/assets/planet_green.svg";
import MeteorShoot from "../../utils/assets/meteor-shoot.svg";
import planetary from "../../utils/assets/planetary.svg";
import rocket2 from "../../utils/assets/rocket.svg";
import planetYellow2 from "../../utils/assets/planet-yellow.svg";
import planetYp from "../../utils/assets/planet-yp.svg";
import onlineShop from "../../utils/assets/online-shopping.svg";
import profileTrain from "../../utils/assets/profile-train.svg";
import performance from "../../utils/assets/performance.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import planetBlu from "../../utils/assets/planet_blue.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import shootingStar from "../../utils/assets/shooting.png";
// import computer from "../../utils/assets/computer-file-globe-Digital Marketing.svg";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ADD_NAVBAR } from "../../actions"


class FashionDetail extends Component {

  render() {
    // const { remove } = this.props
    return (
      <body id="product-detail" className="fashion">        
        <div className="h-90"></div>
        <img src={planetGreen} width="160" alt="Vision lang" className="planet-green img-fluid illustration-mob" />
        <div className="hero-section crowd">
          <div className="bg-sky">
            <div className="container">
              <div className="content txt-center spacing">
                <img src={planetPink} alt="Vision lang" width="400" className="planet-pink img-fluid illustration-mob" />
                <div className="row dis-f">
                  <h1>Fashion Detection</h1>
                  <div className="col-md-8 col-sm-12">
                    <h2>Love an outfit? Upload the image and shop the look instantly</h2>
                    <p>Search for the products without typing a single word. Just one image is enough for us 
                      to know what you are looking for. We are making your fashion discovery fun and easy 
                      with our feature-packed visual search tool.</p>
                    <p>Not every style suits everybody, and our solution helps you save time that you spend 
                      on exploring thousands of online options that aren&apos;t actually meant for you.
                      It empowers you to discover the styles you think work best for you or get 
                      AI-powered style recommendations from the fashion influencers who are just
                      like your body and personality types.</p>
                    <div className="train-model">
                      <h3>How we train our model:</h3>
                      <div className="row dis-f">	
                        <div className="col-sm-3">							
                          <img src={profileTrain} alt="Vision lang" className="perform img-fluid" ></img>
                        </div>
                        <div className="col-sm-9">	
                          <p>We learn from your profile: Once you create your unique profile and provide us 
                            with the details like height, weight, and all the fit issues you come across
                            while buying clothing. We train our model to find the best pieces for you, 
                            eliminating those fit issues.</p>
                        </div>
                      </div>
                      <div className="row dis-f">	
                        <div className="col-sm-3">							
                          <img src={onlineShop} alt="Vision lang" className="perform img-fluid" />
                        </div>
                        <div className="col-sm-9">	
                          <p>Your buying and returning patterns: Each time you keep, exchange, or return any 
                            product, we record every essential measurement detail and other parameters such
                            as density and elasticity. The more we know about what you buy or return, the 
                            better our AI recommendations get.</p>
                        </div>
                      </div>
                      <div className="row dis-f">	
                        <div className="col-sm-3">							
                          <img src={performance} alt="Vision lang" className="perform img-fluid"></img>
                        </div>
                        <div className="col-sm-9">	
                          <p>Human feedback: Personalized fashion is all about overcoming the major issues 
                            people face in real-life, and there is nothing better than scrutinizing human 
                            feedback to train our AI solution to turn it into your personal stylist.</p>
                        </div>
                      </div>
                    </div>
                    {/* <Link  to={{pathname:'/keyword-demo', aboutProps:{api:'object_detection_api', name:"Object Detection"}}}  className="run-btn">Run Demo</Link> */}
                    <Link  to={{pathname:"/demo/Fashion-detection"}}  className="run-btn demo-btn">Run Demo</Link>
                  </div>
                </div>				
              </div>
              <img src={cldMed} width="200" alt="Vision lang" className="cld-med img-fluid illustration-mob" />
              <img src={ufoO} width="300" alt="Vision lang" className="ufo img-fluid illustration-mob" />
            </div>
          </div>
        </div>
        <div className="process-section spacing bg-twinkle">
          <div className="product bg-purple">
            <div className="spacing-p">
              <div className="bg-twinkle">                        				
                <img src={planetBlu} alt="Vision lang" className="planet-ring img-fluid illustration-mob" />
               
                <img src={planetYp} alt="Vision lang" width="400" className="planet-yp img-fluid illustration-mob" />
                <div className="container">
                  <div className="case-title">
                    <h2 className="txt-center">Use Cases and Applications</h2>
                  </div>
                  <div className="internal-bus space-margin">						
                    <img src={MeteorShoot} alt="Vision lang" className="meteor-shoot img-fluid illustration-mob" />
                    <div className="row dis-f">
                      <div className="col-md-6 p-right dis-f">
                        <img src={Visual} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-left">
                        <h4>Retail smarter using visual search AI  </h4>
                        <p className="visual-ai">Empower your customers to shop their favorite 
                          styles using our image search technology powered 
                          by AI. Skyrocket your sales like never before. It 
                          works in three simple steps:</p>
                        <div className="dis-fl t-left"><span><b>Upload-</b></span>
                          <p>Upload the image with the products you liked.</p></div>
                        <div className="dis-fl t-left"><span><b>Spot-</b></span>
                          <p>A list of similar products will be displayed, and
                            customers can spot the best product as per their liking and budget.</p></div>
                        <div className="dis-fl t-left"><span><b>Shop-</b></span>
                          <p>Finally, add the products to the shopping cart or save in the wishlist.</p></div>
                      </div>
                    </div>
                  </div>
                  <div className="customer space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={Deep} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>AI-powered deep tagging</h4>
                        <p>Our professionals have created this tool using deep learning techniques 
                          for advanced tagging. This model helps recognize products, accurately 
                          categorize them, and automatically create relevant tags for entire catalogs. 
                          Tagging introduces convenience for customers and retailers to filter, 
                          discover, and keep the item data instantly accessible.</p>
                      </div>
                    </div>
                  </div>
                  <div className="bus-intel space-margin">							
                    <img src={planetRing} alt="Vision lang" className="planet_blue img-fluid illustration-mob" />	
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={EnhanceUser} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6">
                        <h4>Enhance user experience</h4>
                        <p>Provide a tailor-made experience to your 
                          customers and show them exactly what they 
                          desire to purchase. Help them shop smartly, 
                          quickly, and eliminate customer frustration.</p>
                      </div>
                    </div>
                  </div>
                  <div className="customer space-margin profile-social">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={Profile} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Provide suggestions based on your
                          customers’ social profile</h4>
                        <p>Make relevant suggestions based on the products and photos your customers 
                          actually use, how they wear them, what products will look good with their 
                          common outfits or latest trends. Analyze and suggest products automatically
                          based on what your customers use the most. Here are the benefits for the 
                          customers:</p>
                        <p><span><b>-</b></span> Get product suggestions based on what your 
                          favorite influencers and celebrities wear. Compare 
                          thousands of stores and get the exact looks like your 
                          favorite bloggers and celebrities but on a budget.</p>
                        <p><span><b>-</b></span>   Effortlessly analyze fashion trends around the world.</p>
                      </div>
                    </div>
                  </div>
                  <div className="bus-intel space-margin">
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={celeb} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6">
                        <h4>Celebrity clothes detection</h4>
                        <p>Detect celebrity styles and then sell similar products from
                          your own inventory for better profits.</p>
                      </div>
                    </div>
                  </div>
                  <div className="customer space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={Online} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Take your customers online</h4>
                        <p>Let your customers take a picture of the in-
                          store products and view similar products in 
                          your online catalog and buy from there.</p>
                      </div>
                    </div>
                  </div>
                  <div className="bus-intel space-margin">
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={Recommend} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6">
                        <h4>Smarter similar product recommendations</h4>
                        <p>Recommend visually similar products to your eCommerce site
                          visitors and increase the chances of conversion.</p>
                      </div>
                    </div>
                  </div>
                  <div className="customer space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={Personal} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Personalized recommendations</h4>
                        <p>Our trained model works like a personal stylist and offers recommendations 
                          with maximum accuracy according to the customer&apos;s personality. Increase 
                          sales with smart and personalized recommendations for every individual
                          customer. </p>
                      </div>
                    </div>
                  </div>
                  <div className="bus-intel space-margin">
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={Reduce} className="img-fluid" width="350" alt="Vision lang" />
                      </div>
                      <div className="col-md-6">
                        <h4>Reduce returns</h4>
                        <p>Minimize the chances of product returns by selling products according to what 
                          your customers want to buy and what will look good on them.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>	
          </div>
        </div>
        <div className="ind bg-sky">
          <div className="industries txt-center bg-twinkle"><div className=" spacing">
            <img src={shootingStar} alt="Vision lang" className="shoot img-fluid illustration-mob" ></img>
            <img src={planetGreen2} alt="Vision lang" className="planet_green img-fluid illustration-mob" ></img>
            <div className="container">
              <h3>Industries that can benefit from our Fashion detection system</h3>
              <div className="row dis-f no-gutters">
                <div className="col-md-5 col-10">
                  <a href="#">Fashion houses</a>
                  <a href="#">eCommerce businesses</a>
                </div>
              </div>
            </div>			
            <img src={planetary} alt="Vision lang" className="planetary img-fluid illustration-mob" />
          </div>
          </div>
        </div>
        <div className="pricing txt-center spacing bg-twinkle">
          <div className="container">
            <img src={cldSml} alt="Vision lang" className="cloud-small img-fluid illustration-mob" />
            <img src={rocket2} alt="Vision lang" className="rocket img-fluid illustration-mob" />
            <h2>Pricing</h2>
            <div className="row dis-f">
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 0.00</span>
                    <h5>Basic</h5>
                    <p>10 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4 mrgn">
                <div className="dis-f txt-center">
                  <div className="price-content middle-price">
                    <span>$ 25.00</span>
                    <h5>Advanced</h5>
                    <p>200 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="middle-price txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 100.00</span>
                    <h5>Pro</h5>
                    <p>Unlimited</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
            </div>
          </div>		
          <img src={planetYellow2} alt="Vision lang" className="planet-yellow img-fluid illustration-mob" />
        </div>	
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">			
            <img src={cloudM} alt="Vision lang" className="cloud-m  img-fluid illustration-mob" />	
            <img src={planetPnk} alt="Vision lang" className="planet-pnk img-fluid illustration-mob" />	
          </div>
          <div className="socialmedia dis-f">
            <a href="#"><img src={faceBook} width="20" alt="Vision lang" /></a>
            <a href="#"><img src={linkedin} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={twitter} width="40" alt="Vision lang" /></a>
            <a href="#"><img src={insta} width="40" alt="Vision lang" /></a>
          </div>
          <p>Copyright &#169; 2020 Vision lang Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="Vision lang" className="cloud-btm img-fluid illustration-mob" ></img>
        </footer>
      </body>
    );
  }




}
// KeywordExtraction.propTypes = {
//   remove: PropTypes.fun,
// };

// function mapStateToProps(state, ownProps) {
//   // console.log(state) // state
//   // console.log(ownProps) // ownProps
// }

function mapDispatchToProps (dispatch) {
  return {
    remove: (data) => dispatch(ADD_NAVBAR(data)),
  }
}
export default connect(
  null,
  mapDispatchToProps
)(FashionDetail)
