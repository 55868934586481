import React, { Component } from "react"
import { connect } from "react-redux"
import "../../style/product-detail.scss"
import "../../style/lpr.scss"
// import log from "../../utils/assets/bg-log.svg";
import planetGreen from "../../utils/assets/planet-green.svg";
import astRound from "../../utils/assets/asteroid-round.svg";
import planetPink from "../../utils/assets/planet-pink.svg";
import planetPin from "../../utils/assets/planet_pink.svg";
import planetB from "../../utils/assets/planet-blue.svg";
import cldSml from "../../utils/assets/cloud-small.svg";
import cldMed from "../../utils/assets/cloud-med.svg";
import ufoO from "../../utils/assets/ufo-o.svg";
import cloudBtm from "../../utils/assets/cloud-btm.svg";
import planetRing from "../../utils/assets/planet-ring.svg";
import lpr1 from "../../utils/assets/lpr1.svg";
import lpr2 from "../../utils/assets/lpr2.svg";
import lpr3 from "../../utils/assets/lpr3.svg";
import lpr4 from "../../utils/assets/lpr4.svg";
import planetGreen2 from "../../utils/assets/planet_green.svg";
import planetary from "../../utils/assets/planetary.svg";
import rocket2 from "../../utils/assets/rocket.svg";
import planetYellow2 from "../../utils/assets/planet-yellow2.svg";
import faceBook from "../../utils/assets/facebook.png";
import linkedin from "../../utils/assets/linkedin.png";
import twitter from "../../utils/assets/twitter.png";
import insta from "../../utils/assets/instagram.png";
import planetPnk from "../../utils/assets/planet-pnk.svg";
import cloudM from "../../utils/assets/cloud-m.svg";
import shootingStar from "../../utils/assets/shooting.png";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ADD_NAVBAR } from "../../actions"


class LprDetail extends Component {

  render() {
    // const { remove } = this.props
    return (
      <body id="product-detail" className="lpr"> 
        <img src={planetGreen} width="160" alt="1touch" className="planet-green img-fluid illustration-mob" />       
        <div className="h-90"></div>
        <div className="hero-section">
          <div className="bg-sky">
            <div className="container content txt-center spacing">			
              <img src={planetPink} alt="1touch" width="400" className="planet-pink img-fluid illustration-mob" />
              <div className="row no-gutters dis-f">
                <h1>License Plate Recognition</h1>
                <div className="col-md-8 col-sm-12 lpr-padding">
                  <h2>A perfect solution for enhanced traffic management and security </h2>
                  <p>Automatic License Plate Recognition system by 1Touch is an ideal solution that works accurately and accepts images in all formats. Our team has engineered this solution to offer an optimum solution to identify vehicles by their registration plates in all environments.</p>
                  {/* <Link  to={{pathname:'/keyword-demo', aboutProps:{api:'object_detection_api', name:"Object Detection"}}}  className="run-btn">Run Demo</Link> */}
                  <Link to={{pathname:"/optocal-demo/LPR"}}  className="run-btn demo-btn">Run Demo</Link>
                </div>
              </div>	
            </div>
            <img src={ufoO} width="300" alt="1touch" className="ufo img-fluid illustration-mob" />
          </div>
        </div>
        <div className="process-section spacing bg-twinkle">
          <img src={cldMed} width="200" alt="1touch" className="cld-med img-fluid illustration-mob" />
          <div className="bg-star"><div className="content case-title"><h2 className="txt-center">Use Cases and Applications</h2></div></div>
          <div className="product bg-purple">
            <div className="spacing-p">
              <div className="bg-twinkle">							
                <img src={planetB} alt="1touch" className="planet-ring img-fluid illustration-mob" />                
                <img src={planetPin} alt="1touch" className="planet_pin img-fluid illustration-mob" />
                <div className="container">
                  <div className="internal-bus space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-right dis-f">
                        <img src={lpr1} className="img-fluid" width="350" alt="1touch" />
                      </div>
                      <div className="col-md-6 p-left">
                        <h4>Parking management</h4>
                        <p>It makes parking management fast and smooth as you can easily track vehicle entries and exits.</p>
                      </div>
                    </div>
                  </div>
                  <div className="customer space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={lpr2} className="img-fluid" width="350" alt="1touch" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Road safety</h4>
                        <p>Our advanced AI License Plate Recognition system helps detect the vehicles that exceed the speed limit and violate other traffic rules.</p>
                      </div>
                    </div>
                  </div>
                  <div className="bus-intel space-margin">							
                    <img src={planetRing} alt="1touch" className="planet_blue img-fluid illustration-mob" />                    
                    <div className="row  dis-f">
                      <div className="col-md-6 dis-f">
                        <img src={lpr3} className="img-fluid" width="350" alt="1touch" />
                      </div>
                      <div className="col-md-6">
                        <h4>Identify stolen vehicles</h4>
                        <p>Utilize this advanced solution to detect stolen vehicles. The recognized license plates on the roads are compared to the reported (stolen/missing) vehicles, and quick actions can be taken by authorities when a stolen vehicle is found.</p>
                      </div>
                    </div>
                  </div>
                  <div className="customer space-margin">
                    <div className="row dis-f">
                      <div className="col-md-6 p-left order-md-2  dis-f">
                        <img src={lpr4} className="img-fluid" width="350" alt="1touch" />
                      </div>
                      <div className="col-md-6 p-right order-md-1">
                        <h4>Effortless toll management</h4>
                        <p>Automate the toll collection process based on the license plate number without requiring additional details from vehicle owners.</p>
                      </div>                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="industries txt-center bg-sky bg-twinkle">
          <img src={astRound} alt="1touch" className="ast-round img-fluid illustration-mob" />
          <img src={shootingStar} alt="1touch" className="shoot img-fluid illustration-mob" />
          <img src={planetGreen2} alt="1touch" className="planet_green img-fluid illustration-mob" />
          <div className=" spacing">
            <div className="container">
              <h3>Industries that can benefit from our License Plate Recognition system</h3>
              <div className="row dis-f no-gutters">
                <div className="col-md-5 col-10">
                  <a href="#">Traffic police</a>
                  <a href="#">Command forces</a>
                  <a href="#">Organizations and public places</a>
                </div>
              </div>
            </div>			
            <img src={planetary} alt="1touch" className="planetary img-fluid illustration-mob" />
          </div>
        </div>
        <div className="pricing txt-center spacing bg-twinkle">
          <div className="container">
            <img src={cldSml} alt="1touch" className="cloud-small img-fluid illustration-mob" />
            <img src={rocket2} alt="1touch" className="rocket img-fluid illustration-mob" />
            <h2>Pricing</h2>
            <div className="row dis-f">
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 0.00</span>
                    <h5>Basic</h5>
                    <p>10 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4 mrgn">
                <div className="dis-f txt-center">
                  <div className="price-content middle-price">
                    <span>$ 25.00</span>
                    <h5>Advanced</h5>
                    <p>200 API/Month<br></br>
                      $ 0.01per extra</p>
                  </div>
                </div>
                <div className="middle-price txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dis-f txt-center">
                  <div className="price-content">
                    <span>$ 100.00</span>
                    <h5>Pro</h5>
                    <p>Unlimited</p>
                  </div>
                </div>
                <div className="txt-center dis-f">
                  <a href="#" className="run-btn">Subscribe</a>
                </div>
              </div>
            </div>
          </div>		
          <img src={planetYellow2} alt="1touch" className="planet-yellow img-fluid illustration-mob" />
        </div>	
        <footer className="txt-center bg-twinkle">
          <div className="footer-spacing">			
            <img src={cloudM} alt="1touch" className="cloud-m  img-fluid illustration-mob" />	
            <img src={planetPnk} alt="1touch" className="planet-pnk img-fluid illustration-mob" />	
          </div>
          <div className="socialmedia dis-f">
            <a href="#"><img src={faceBook} width="20" alt="1touch" /></a>
            <a href="#"><img src={linkedin} width="40" alt="1touch" /></a>
            <a href="#"><img src={twitter} width="40" alt="1touch" /></a>
            <a href="#"><img src={insta} width="40" alt="1touch" /></a>
          </div>
          <p>Copyright &#169; 2020 1Touch Development Pvt Ltd. All Rights Reserved Privacy Policy</p>
          <img src={cloudBtm} alt="1touch" className="cloud-btm img-fluid illustration-mob" />
        </footer>
      </body>
    );
  }




}

function mapDispatchToProps (dispatch) {
  return {
    remove: (data) => dispatch(ADD_NAVBAR(data)),
  }
}
export default connect(
  null,
  mapDispatchToProps
)(LprDetail)
