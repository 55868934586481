import { combineReducers } from "redux"
import user from "./user"
import products from "./productReducer";


export default combineReducers({
  user,
  products,
})

// import {
//   ADD_NAVBAR,
// } from '../actions'

// const initialDocState = {
//   navbar: true,
//   path: '/',
// }

// function docs (state = initialDocState, action) {
//   const { navbar, path } = action

//   switch (action.type) {
//   case ADD_NAVBAR :
//     return {
//       ...state,
//       navbar,
//       path
//     }

//   default :
//     return state
//   }
// }

